import { Document, Page, Text, View, StyleSheet, Link } from "@react-pdf/renderer";
import { format } from "date-fns";
import InvoicePdfItems from "./InvoicePdfItems";
import { CustomInvoiceEntity, CustomShipment, Maybe } from "__generated__/graphql";
import useConfiguration from "utils/hooks/useConfiguration";
import { isEmpty } from "lodash";

interface Props extends Omit<CustomInvoiceEntity, "id">, ReturnType<typeof useConfiguration> {
  total: number;
}

export default function InvoicePdf({
  code,
  createdAt,
  paymentStatus,
  paymentGatewayResponse,
  shipment,
  total,
  config,
  company,
  overcharge,
}: Props) {
  if (!shipment || isEmpty(config) || isEmpty(company)) return null;

  const paymentDate =
    paymentGatewayResponse?.created_at && paymentStatus ? new Date(paymentGatewayResponse.created_at) : null;

  const invoiceNum = code;
  const invoiceDate = new Date(createdAt ?? Date.now());
  const invoiceStatus = paymentStatus ? (
    <>
      <Text style={[styles.statusText, styles.paidText]}>PAID</Text>
      {paymentDate && (
        <View style={[{ paddingVertical: 0, fontSize: 10 }]}>
          <Text>{format(paymentDate, "yyyy-MM-dd hh:mmaaa")}</Text>
        </View>
      )}
    </>
  ) : (
    <Text style={[styles.statusText, styles.unpaidText]}>UNPAID</Text>
  );
  const from = shipment.from;
  const to = shipment.to;

  const invoiceLink = `${config?.FRONTEND_URL}/invoices/${code}`;

  return (
    <Document>
      <Page size="A4" style={{ padding: 24, fontFamily: "Courier" }}>
        <Text style={{ fontSize: 14 }}>
          <Link src={invoiceLink}>View invoice online</Link>
        </Text>
        <Text style={[styles.invoiceLabel, styles.bold]}>Invoice</Text>
        <View style={styles.row}>
          <View>
            <Text style={styles.fromLabel}>From:</Text>
            <Text style={styles.fromContent}>{company?.name}</Text>
            <Text style={styles.fromContent}>{company?.address}</Text>
            <Text style={styles.fromContent}>{company?.phone}</Text>
            <Text style={styles.fromContent}>{company?.email}</Text>
          </View>
          <View style={styles.col}>{invoiceStatus}</View>
        </View>
        <View style={styles.payee}>
          <Text style={styles.fromLabel}>To:</Text>
          <Text style={styles.fromContent}>
            <Receiver shipment={shipment} />
          </Text>
          <Text style={styles.fromContent}>
            <Receiver shipment={shipment} returnValue="phone" />
          </Text>
          <Text style={styles.fromContent}>
            <Receiver shipment={shipment} returnValue="address" />
          </Text>
        </View>
        <View style={styles.divider} />
        <View style={[styles.row, { fontSize: 14 }]}>
          <View style={styles.col}>
            <View style={[styles.invoiceDetailText, styles.row, { paddingVertical: 0 }]}>
              <Text>Invoice ID: </Text>
              <Text>{invoiceNum}</Text>
            </View>
            <View style={[styles.invoiceDetailText, styles.row, { paddingVertical: 0 }]}>
              <Text>Invoice Date: </Text>
              <Text>{format(invoiceDate, "yyyy-MM-dd hh:mmaaa")}</Text>
            </View>
          </View>
          <View style={[styles.col, styles.invoiceDetailText]}>
            <View style={[styles.invoiceDetailText, styles.row, { paddingVertical: 0 }]}>
              <Text>From: </Text>
              <Text>{from}</Text>
            </View>
            <View style={[styles.invoiceDetailText, styles.row, { paddingVertical: 0 }]}>
              <Text>To: </Text>
              <Text>{to}</Text>
            </View>
          </View>
        </View>
        <View style={styles.divider} />
        {shipment && <InvoicePdfItems shipment={shipment} total={total} overcharge={overcharge ?? 0} />}
        <Text style={styles.disclaimer} fixed>
          {company.invoice_disclaimer}
        </Text>
      </Page>
    </Document>
  );
}

function Receiver({
  shipment,
  returnValue = "name",
}: {
  shipment: Maybe<CustomShipment>;
  returnValue?: "name" | "phone" | "address";
}) {
  const { receiver, alien } = shipment ?? {};
  const isAlien = receiver?.role?.name === "Alien";
  const receiverData = isAlien ? alien : receiver;

  const address = isAlien ? "" : receiver?.address;

  switch (returnValue) {
    case "address":
      return <>{address ?? ""}</>;
    case "phone":
      return <>{receiverData?.phone ?? ""}</>;
    default:
      return (
        <>
          {receiverData?.firstName} {receiverData?.lastName}
        </>
      );
  }
}

const styles = StyleSheet.create({
  invoiceLabel: {
    fontSize: 32,
    textAlign: "right",
  },
  fromLabel: {
    fontSize: 12,
    color: "#555",
    fontWeight: "bold",
  },
  fromContent: {
    fontSize: 14,
  },
  payee: {
    marginVertical: 24,
  },
  divider: {
    marginVertical: 8,
    borderBottom: "1px solid #ccc",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 4,
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  col: {
    flexDirection: "column",
    paddingVertical: 4,
  },
  statusText: {
    fontWeight: "bold",
    fontSize: 40,
    marginTop: 22,
    padding: 4,
  },
  paidText: {
    color: "green",
    border: "1px solid green",
  },
  unpaidText: {
    color: "red",
    border: "1px solid red",
  },
  invoiceDetailText: {
    fontSize: 14,
    marginBottom: 4,
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Courier-Bold",
  },
  disclaimer: {
    fontSize: 10,
    marginTop: "auto",
  },
});
