import { ReactNode } from "react";
import { Flex, Spinner, VStack } from "@chakra-ui/react";
import VisibleBox from "./VisibleBox";

interface Props<T> {
  list: T[];
  headerComponent?: ReactNode;
  renderItem: (item: T) => JSX.Element;
  isLoading?: boolean;
  threshhold?: number;
  emptyComponent?: JSX.Element;
  extrackKey?: (item: T) => string;
  onReachEnd?: () => void;
}

export default function InfiniteList<T>({
  list,
  threshhold,
  isLoading,
  emptyComponent,
  headerComponent,
  renderItem,
  extrackKey,
  onReachEnd,
}: Props<T>) {
  return (
    <Flex direction={"column"} height={"100%"} width={"100%"} alignItems={"center"} paddingBottom={24}>
      <VStack width={"100%"} my={2} spacing={8}>
        {headerComponent}
        {list.map((item, index) => (
          <VisibleBox
            w={"100%"}
            key={extrackKey ? extrackKey(item) : Math.random().toString(36)}
            onVisible={() => {
              if (index + (threshhold ?? 0) !== list.length) return;
              onReachEnd?.();
            }}
          >
            {renderItem(item)}
          </VisibleBox>
        ))}
      </VStack>
      {isLoading && (
        <Flex width={"100%"} justifyContent={"center"}>
          <Spinner colorScheme="purple" size={"xl"} />
        </Flex>
      )}
      {!isLoading && list.length === 0 && emptyComponent}
    </Flex>
  );
}
