import { Button, useToast } from "@chakra-ui/react";
import usePayment from "utils/hooks/usePayment";
import { getGqlError } from "utils/gpq-helpers";

interface Props {
  paymentGatewayResponse?: Record<string, any>;
}

export default function ValidatePaymentButton({ paymentGatewayResponse }: Props) {
  const {
    validate: { validatePayment },
  } = usePayment();
  const toast = useToast();

  if (!paymentGatewayResponse?.transaction_id) return null;

  const handleValidatePayment = async () => {
    try {
      await validatePayment({
        variables: {
          input: {
            ...paymentGatewayResponse,
          },
        },
      });
      toast({
        title: "Payment validated successfully",
        status: "success",
      });
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  return (
    <Button colorScheme="green" onClick={handleValidatePayment}>
      Validate Payment
    </Button>
  );
}
