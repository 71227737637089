import { FragmentType, gql, useFragment } from "__generated__";
import { SHIPMENT_ENTITY, SHIPMENT_ITEMS_ENTITY } from "./query.graphql";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import { Box, useToast, ButtonGroup, Button, Tr, Td, Text } from "@chakra-ui/react";
import numeral from "numeral";
import { MdEdit, MdDelete } from "react-icons/md";
import { getGqlError } from "utils/gpq-helpers";

interface ContentProps {
  shipmentItem: FragmentType<typeof SHIPMENT_ITEMS_ENTITY>;
  shipmentId: string;
  index?: number;
  deletable?: boolean;
  onSumChange?: (sum: number) => void;
  onEdit?: () => void;
}

const deleteMutation = gql(`
	mutation DeleteShipmentItem($id: ID!) {
		deleteShipmentItem(id: $id) {
			data {
				id
			}
		}
	}
`);

export default function ShipmentItemRow({
  shipmentItem,
  shipmentId,
  index,
  deletable,
  onSumChange,
  onEdit,
}: ContentProps) {
  const { id, attributes } = useFragment(SHIPMENT_ITEMS_ENTITY, shipmentItem);
  const total = (attributes?.unit_price ?? 0) * (attributes?.quantity ?? 0);
  useEffect(() => {
    onSumChange?.(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
  const [deleteShipmentItem, { loading: deleting }] = useMutation(deleteMutation, {
    update(cache, { data }) {
      if (!data?.deleteShipmentItem) return;
      const frag = cloneDeep(
        cache.readFragment({
          id: `ShipmentEntity:${shipmentId}`,
          fragment: SHIPMENT_ENTITY,
          fragmentName: "ShipmentFragment",
        })
      );

      if (!frag?.attributes?.shipment_items?.data) return;

      frag.attributes.shipment_items.data = frag.attributes.shipment_items.data.filter(
        (item) => item?.id !== data?.deleteShipmentItem?.data?.id
      );

      cache.writeFragment({
        id: `ShipmentEntity:${shipmentId}`,
        fragment: SHIPMENT_ENTITY,
        fragmentName: "ShipmentFragment",
        data: frag,
      });
    },
  });
  const toast = useToast();

  const handleDelete = async () => {
    try {
      await deleteShipmentItem({
        variables: {
          id: id ?? "",
        },
      });
      onSumChange?.(0);
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  const onClickDelete = () => {
    if (deletable) {
      if (toast.isActive(`delete-shipment-item-${id}`)) return;
      toast({
        id: `delete-shipment-item-${id}`,
        render: ({ onClose }) => (
          <Box bg={"red.500"} padding={4} rounded={4}>
            <Text textAlign={"center"}>
              Are you sure you want to delete this item? <br />"{attributes?.description}"
            </Text>
            <ButtonGroup w={"100%"} justifyContent={"end"} mt={3} spacing={3}>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  await handleDelete();
                  onClose();
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Box>
        ),
      });
      // handleDelete();
    }
  };

  return (
    <Tr>
      {index && <Td>{index}</Td>}
      <Td>{attributes?.description}</Td>
      <Td isNumeric>{attributes?.quantity}</Td>
      <Td isNumeric>{numeral(attributes?.unit_price).format("0,00.00")}</Td>
      <Td isNumeric>{numeral(total).format("0,00.00")}</Td>
      {(onEdit || deletable) && (
        <Td>
          <ButtonGroup>
            {onEdit && <Button colorScheme="blue" onClick={onEdit} leftIcon={<MdEdit />} />}
            {deletable && (
              <Button
                colorScheme="red"
                onClick={onClickDelete}
                leftIcon={<MdDelete />}
                isLoading={deleting}
                disabled={deleting}
              />
            )}
          </ButtonGroup>
        </Td>
      )}
    </Tr>
  );
}
