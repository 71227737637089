import { Text, Box, Input, Button, VStack, useToast } from "@chakra-ui/react";
import Layout from "components/Layout";
import { gql } from "__generated__/gql";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Logo from "components/Logo";
import { ColorModeSwitcher } from "components/ColorModeSwitcher";
import Link from "components/Link";
import PasswordInput from "components/PasswordInput";

const login = gql(`
  mutation LoginMutation($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
  }
}
`);
export default function Main() {
  const toast = useToast();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    identifier: "",
    password: "",
  });
  const [mutation, { data, loading, error }] = useMutation(login);

  const handleSignIn = async () => {
    void mutation({ variables: { input: credentials } });
  };

  if (data) {
    data.login.jwt && localStorage.setItem("token", data.login.jwt);
  }
  useEffect(() => {
    if (error) toast({ title: error.message, status: "error" });
    if (data) {
      toast({
        title: "Login successful",
        status: "success",
      });

      navigate("/shipments");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, toast]);

  return (
    <Layout hideHeader>
      <VStack spacing={8} my={"auto"}>
        <ColorModeSwitcher position={"fixed"} top={4} right={4} zIndex={999} />
        <Logo width={240} />
        <Box w={{ base: "100%", md: "400px", lg: "480px" }} shadow={"2xl"} px={4} py={16} rounded={8}>
          <VStack spacing={4}>
            <Text fontSize={"2xl"}>Sign in to your account</Text>
            <Input
              placeholder="Enter email or username"
              size={"lg"}
              value={credentials.identifier}
              onChange={({ currentTarget: { value } }) => setCredentials({ ...credentials, identifier: value })}
            />
            <PasswordInput
              placeholder="Enter password"
              type="password"
              size={"lg"}
              value={credentials.password}
              onChange={({ currentTarget: { value } }) => setCredentials({ ...credentials, password: value })}
            />
            <Button
              w="100%"
              colorScheme="purple"
              size={"lg"}
              onClick={handleSignIn}
              isDisabled={!credentials.identifier || !credentials.password}
              isLoading={loading}
            >
              Continue
            </Button>
          </VStack>
        </Box>
        <Link to="/forgot-password">Forgot password?</Link>
      </VStack>
    </Layout>
  );
}
