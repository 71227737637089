import { Text, Box, Button, VStack, useToast } from "@chakra-ui/react";
import Layout from "components/Layout";
import { gql } from "__generated__/gql";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "components/Logo";
import { ColorModeSwitcher } from "components/ColorModeSwitcher";
import PasswordInput from "components/PasswordInput";

const resetPassword = gql(`
  mutation ResetMutation($password: String!, $confirm: String!, $code: String!) {
  resetPassword(password: $password, passwordConfirmation: $confirm, code: $code) {
    jwt
  }
}
`);
export default function ResetPassword() {
  const toast = useToast();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [input, setInput] = useState({
    password: "",
    confirm: "",
  });
  const [mutation, { data, loading, error }] = useMutation(resetPassword);

  // valid password strength with Upper, Lower, Number, Special, and min 8 chars
  const validPassword = useMemo(() => {
    return (
      input.password && input.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    );
  }, [input.password]);

  const validConfirm = useMemo(() => {
    return input.password && input.confirm && input.password === input.confirm;
  }, [input.password, input.confirm]);

  const handleSubmit = async () => {
    void mutation({ variables: { ...input, code: query.get("code") ?? "" } });
  };

  if (data) {
    data.resetPassword?.jwt && localStorage.setItem("token", data.resetPassword.jwt);
  }
  useEffect(() => {
    if (error) toast({ title: error.message, status: "error" });
    if (data?.resetPassword?.jwt) {
      toast({
        title: "Password reset successful",
        status: "success",
      });

      navigate("/shipments");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, toast]);

  return (
    <Layout hideHeader>
      <VStack spacing={8} my={"auto"}>
        <ColorModeSwitcher position={"fixed"} top={4} right={4} zIndex={999} />
        <Logo width={240} />
        <Box w={{ base: "100%", md: "400px", lg: "480px" }} shadow={"2xl"} px={4} py={16} rounded={8}>
          <VStack spacing={4}>
            <Text fontSize={"2xl"}>Reset your password</Text>
            <Box>
              <PasswordInput
                placeholder="Enter password"
                type="password"
                size={"lg"}
                value={input.password}
                onChange={({ currentTarget: { value } }) => setInput({ ...input, password: value })}
                isInvalid={!!input.password && !validPassword}
              />
              <Text fontSize={"sm"} textAlign="start" mt="2" ps="2">
                Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character
              </Text>
            </Box>
            <PasswordInput
              placeholder="Confirm password"
              type="password"
              size={"lg"}
              value={input.confirm}
              onChange={({ currentTarget: { value } }) => setInput({ ...input, confirm: value })}
              isInvalid={!!input.confirm && !validConfirm}
            />
            <Button
              w="100%"
              colorScheme="purple"
              size={"lg"}
              onClick={handleSubmit}
              isDisabled={!validPassword || !validConfirm}
              isLoading={loading}
            >
              Continue
            </Button>
          </VStack>
        </Box>
      </VStack>
    </Layout>
  );
}
