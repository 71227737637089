import { useMutation } from "@apollo/client";
import { gql } from "__generated__";
import { InputMaybe, PaginationArg, ShipmentFiltersInput } from "__generated__/graphql";
import { QUERY_MANY } from "components/shipment/query.graphql";

const deleteMutation = gql(`
  mutation DeleteShipment($id: ID!) {
    deleteShipment(id: $id) {
      data {
        id
      }
    }
  }
`);

interface Options {
	pagination: InputMaybe<PaginationArg> | undefined;
	filters: InputMaybe<ShipmentFiltersInput> | undefined;
	sort: InputMaybe<string[]> | undefined;
}

export default function useShipmentMutations({ pagination, filters, sort }: Partial<Options>) {
	const [deleteShipment, { loading: deleting }] = useMutation(deleteMutation, {
		update(cache, { data }) {
			if (!data?.deleteShipment) return;
			const shipments = cache.readQuery({
				query: QUERY_MANY,
				variables: { pagination, filters, sort },
			})?.shipments;
			if (!shipments) return;
			cache.writeQuery({
				query: QUERY_MANY,
				variables: { pagination, filters, sort },
				data: {
					shipments: {
						...shipments,
						data: shipments.data.filter((shipment) => shipment.id !== data.deleteShipment?.data?.id),
					}
				}
			});
		},
	});

	return {
		delete: { deleteShipment, deleting },
	}
}