import { ReactNode, createContext, useMemo, useState } from "react";

interface State {
  batchPay: boolean;
  invoices: string[];
  processing: boolean;
}
interface Context extends State {
  toggleBatchPay: () => void;
  addBatchPayInvoice: (id: string) => void;
  removeBatchPayInvoce: (id: string) => void;
  clearBatchPayInvoices: () => void;
  toggleProcessing: () => void;
}

const OmicContext = createContext<Context>({
  batchPay: false,
  invoices: [],
  processing: false,
  toggleBatchPay: () => {},
  addBatchPayInvoice: () => {},
  removeBatchPayInvoce: () => {},
  clearBatchPayInvoices: () => {},
  toggleProcessing: () => {},
});

interface Props {
  children: ReactNode;
}
export default function OmicContextProvider({ children }: Props) {
  const [state, setState] = useState<State>({
    batchPay: false,
    invoices: [],
    processing: false,
  });
  const context = useMemo(
    () => ({
      ...state,
      toggleBatchPay: () => setState((s) => ({ ...s, batchPay: !s.batchPay })),
      addBatchPayInvoice: (id: string) => setState((s) => ({ ...s, invoices: [...s.invoices, id] })),
      removeBatchPayInvoce: (id: string) => setState((s) => ({ ...s, invoices: s.invoices.filter((i) => i !== id) })),
      clearBatchPayInvoices: () => setState((s) => ({ ...s, invoices: [] })),
      toggleProcessing: () => setState((s) => ({ ...s, processing: !s.processing })),
    }),
    [state]
  );
  return <OmicContext.Provider value={context}>{children}</OmicContext.Provider>;
}

export { OmicContext };
