import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Flex,
  HStack,
  Text,
  VStack,
  useColorModeValue,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdAdd, MdLocalShipping, MdLogout, MdPayments, MdRequestQuote } from "react-icons/md";
import Header from "./Header";
import useCurrentUser from "utils/hooks/useCurrentUser";
import { lowerCase } from "lodash";
import useOmic from "utils/hooks/useOmic";

interface Props {
  children: React.ReactNode;
  hideHeader?: boolean;
  headerBottom?: React.ReactNode;
}

export default function Layout({ children, headerBottom, hideHeader }: Props) {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const toast = useToast();
  const menuBg = useColorModeValue("gray.50", "gray.900");
  const [isLargerThan576] = useMediaQuery("(min-width: 576px)");
  const { batchPay, processing, toggleBatchPay, clearBatchPayInvoices } = useOmic();
  const iconSize = isLargerThan576 ? 48 : 36;

  const handleConfirmLogout = () => {
    if (toast.isActive("logout")) return;
    toast({
      id: "logout",
      render: ({ onClose }) => (
        <Box color="white" p={3} bg="blackAlpha.900" rounded={4}>
          <Text color="white">Are you sure you want to logout?</Text>
          <ButtonGroup w={"100%"} justifyContent={"end"} mt={3} spacing={3} size={"sm"}>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={"outline"}
              colorScheme="red"
              onClick={() => {
                localStorage.removeItem("token");
                window.location.reload();
              }}
            >
              Yes Logout
            </Button>
          </ButtonGroup>
        </Box>
      ),
    });
  };

  const menu = [
    {
      icon: <MdLogout size={iconSize} />,
      label: "Logout",
      action: handleConfirmLogout,
    },
    {
      icon: <MdAdd size={iconSize} />,
      label: "New Shipment",
      allow: ["director", "staff"],
      href: "/shipments/new",
    },
    {
      icon: <MdLocalShipping size={iconSize} />,
      label: "Shipments",
      href: "/shipments",
    },
    {
      icon: <MdRequestQuote size={iconSize} />,
      label: "Invoices",
      href: "/invoices",
      allow: ["director"],
    },
    {
      icon: <MdPayments size={iconSize} />,
      label: `${batchPay ? "Cancel" : "Batch"} Pay`,
      action: () => {
        toggleBatchPay();
        navigate("/shipments");

        if (batchPay) clearBatchPayInvoices();
      },
    },
  ];

  return (
    <>
      <Box textAlign="center">
        {!hideHeader && <Header bottom={headerBottom} />}
        <VStack spacing={8} minH={"90vh"} px={3}>
          {children}
        </VStack>
        {!!user && (
          <Box
            position="fixed"
            bottom={0}
            w="100%"
            bg={menuBg}
            py={2}
            display={"flex"}
            justifyContent={"center"}
            zIndex={99}
          >
            <HStack w="600px" justifyContent="space-around">
              {menu
                .filter(({ allow }) => !allow || (user && allow.includes(lowerCase(user?.role.name ?? ""))))
                .map(({ icon, label, href, action }) => (
                  <MenuButton
                    key={label}
                    icon={icon}
                    label={label}
                    onClick={() => {
                      if (href) {
                        navigate(href);
                      }
                      if (action) {
                        action();
                      }
                    }}
                    isDisabled={processing}
                    alignItems={"center"}
                  />
                ))}
            </HStack>
          </Box>
        )}
      </Box>
    </>
  );
}

interface MenuButtonProps extends ButtonProps {
  icon: JSX.Element;
  label: string;
}
function MenuButton({ icon, label, ...buttonProps }: MenuButtonProps) {
  const [isLargerThan576] = useMediaQuery("(min-width: 576px)");
  const buttonDimensions = isLargerThan576 ? { w: `76px`, h: `76px` } : { w: `60px`, h: `60px` };

  return (
    <Button h={buttonDimensions.h} w={buttonDimensions.w} variant="ghost" {...buttonProps}>
      <Flex direction={"column"} align="center">
        {icon}
        <Text fontSize={"x-small"}>{label}</Text>
      </Flex>
    </Button>
  );
}
