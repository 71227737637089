import { gql } from "__generated__";

const FULFILMEN_ENTITY = gql(`
	fragment FulfilmentFragment on FulfilmentEntity {
		id
		attributes {
			createdAt
			fulfilledBy {
				data {
					attributes {
						firstName
						lastName
					}
				}
			}
		}
	}
`);

const SHIPMENT_ENTITY = gql(`
	fragment ShipmentFragment on ShipmentEntity {
		id
		attributes {
			owner {
				data {
					attributes {
						firstName,
						lastName
					}
				}
			}
			from
			to
			note
			receiver {
				data {
					id
					attributes {
						firstName
						lastName
						email
						phone
					}
				}
			}
			shipment_items {
				data {
					id 
					...ShipmentItemFragment
				}
			}
			invoice {
				data {
					id
					attributes {
						code
						paymentStatus
						paymentGatewayResponse
					}
				}
			}
			alien {
				data {
					id
					attributes {
						firstName
						lastName
						phone
					}
				}
			}
			fulfilment {
				data {
					id
					...FulfilmentFragment
				}
			}
			createdAt
		}
	}
`);

const SHIPMENT_ITEMS_ENTITY = gql(`
	fragment ShipmentItemFragment on ShipmentItemEntity {
		id 
		attributes {
			description
			quantity
			unit_price
		}
	}
`);

const SORT = ["createdAt:desc", "updatedAt:desc"];

const QUERY_MANY = gql(`
  query Shipments($filters: ShipmentFiltersInput, $pagination: PaginationArg, $sort: [String]) {
    shipments(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
				id
				...ShipmentFragment
      }
			meta {
				pagination {
					total
					page
					pageSize
					pageCount
				}
			}
    }
  }
`);

const QUERY_ONE = gql(`
  query Shipment($id: ID!) {
    shipment(id: $id) {
      data {
				id
				...ShipmentFragment
      }
    }
  }
`);

export {
	SORT,
	QUERY_MANY,
	QUERY_ONE,
	SHIPMENT_ENTITY,
	SHIPMENT_ITEMS_ENTITY,
	FULFILMEN_ENTITY
}