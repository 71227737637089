import { useQuery } from "@apollo/client";
import Layout from "components/Layout";
import { QUERY_BY_CODE } from "components/invoice/query.graphql";
import { useParams } from "react-router-dom";
import { Button, Divider, Text as HTMLText, useColorModeValue, VStack } from "@chakra-ui/react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import InvoicePdf from "components/invoice/InvoicePdf";
import PayNowButton from "components/invoice/PayNowButton";
import useConfiguration from "utils/hooks/useConfiguration";
import { InvoiceByCodeQuery } from "__generated__/graphql";
import { isAndroid } from "react-device-detect";

export default function ShipmentDetail() {
  const { id } = useParams<{ id: string }>();
  const { data: result } = useQuery(QUERY_BY_CODE, {
    variables: { code: id ?? "" },
    fetchPolicy: "network-only",
  });

  const data = result?.invoiceByCode?.data;
  const total = result?.invoiceByCode?.total ?? 0;

  if (!data) return null;
  return (
    <Layout
      headerBottom={
        <>
          <Divider my={4} colorScheme={"purple"} />
          <HTMLText textAlign={"start"} fontSize={"3xl"}>
            Invoice <em>#{id}</em>
          </HTMLText>
        </>
      }
    >
      <VStack alignItems={"start"} maxW={"800px"} width={"100%"} spacing={"16px"} pb={"200px"} minH={"800px"}>
        <PayNowButton invoice={data} total={total} />
        <Content data={data} total={total} />
      </VStack>
    </Layout>
  );
}

interface ContentProps {
  data: NonNullable<NonNullable<InvoiceByCodeQuery["invoiceByCode"]>["data"]>;
  total: number;
}
function Content({ data, total }: ContentProps) {
  const { config, company } = useConfiguration();
  const downloadBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");

  const pdf = (
    <InvoicePdf
      code={data.code}
      createdAt={data.createdAt}
      paymentStatus={data.paymentStatus}
      paymentGatewayResponse={data.paymentGatewayResponse}
      shipment={data.shipment}
      total={total}
      config={config}
      company={company}
      overcharge={data.overcharge}
    />
  );

  return (
    <>
      {isAndroid && (
        <VStack w={"100%"} bg={downloadBg} height={"400px"} justifyContent={"center"} spacing={8} p={4}>
          <HTMLText fontSize={"sm"}>
            Android does not support PDF preview. Please download the PDF to view it.{" "}
          </HTMLText>
          <PDFDownloadLink document={pdf} fileName={`${data.code}.pdf`}>
            {({ loading }) =>
              loading ? (
                "Loading document..."
              ) : (
                <Button colorScheme="blue" variant={"outline"}>
                  Download PDF
                </Button>
              )
            }
          </PDFDownloadLink>
        </VStack>
      )}
      {!isAndroid && (
        <PDFViewer width={"100%"} height={"900px"}>
          {pdf}
        </PDFViewer>
      )}
    </>
  );
}
