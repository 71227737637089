import { FragmentType, useFragment } from "__generated__";
import { SHIPMENT_ENTITY, SHIPMENT_ITEMS_ENTITY } from "./query.graphql";
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import ShipmentItemForm from "./ShipmentItemForm";
import { isEmpty, sum, values } from "lodash";
import { useMemo, useState } from "react";
import numeral from "numeral";
import ShipmentItemRow from "./ShipmentItemRow";
import useCurrentUser from "utils/hooks/useCurrentUser";
import { STAFF_ROLES } from "constant";

interface Props {
  shipment: FragmentType<typeof SHIPMENT_ENTITY>;
}

export default function ShipmentItems({ shipment }: Props) {
  const user = useCurrentUser();
  const disclosure = useDisclosure();
  const { id, attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const [totals, setTotals] = useState<Record<string, number>>({});
  const items = attributes?.shipment_items?.data ?? [];
  const [edit, setEdit] = useState<FragmentType<typeof SHIPMENT_ITEMS_ENTITY>>();

  const hasPaid = !!attributes?.invoice?.data?.attributes?.paymentStatus;
  const hasInvoice = !!attributes?.invoice?.data;
  const grandTotal = useMemo(() => sum(values(totals)), [totals]);
  const isStaff = STAFF_ROLES.includes(user?.role.name ?? "");
  const canAddMoreItems = items.length < 10;

  return (
    <VStack width={"100%"} alignItems={"start"} spacing={"16px"}>
      {!hasPaid && !hasInvoice && isStaff && (
        <Button colorScheme="yellow" onClick={disclosure.onOpen} isDisabled={!canAddMoreItems}>
          Add Item
        </Button>
      )}

      {!isEmpty(items) && (
        <TableContainer mt={6} width={"100%"}>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Description</Th>
                <Th isNumeric>Qty</Th>
                <Th isNumeric>Rate</Th>
                <Th isNumeric>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((item, index) => (
                <ShipmentItemRow
                  key={`shipment-item-${item?.id}`}
                  shipmentItem={item}
                  shipmentId={id ?? ""}
                  index={index + 1}
                  onSumChange={(sum) => setTotals((prev) => ({ ...prev, [item?.id ?? ""]: sum }))}
                  {...(!hasPaid &&
                    !hasInvoice && {
                      deletable: true,
                      onEdit: () => {
                        setEdit(item);
                        disclosure.onOpen();
                      },
                    })}
                />
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td colSpan={3}></Td>
                <Td isNumeric>Grand Total:</Td>
                <Td isNumeric>{numeral(grandTotal).format("0,00.00")}</Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      )}
      {items.length === 0 && (
        <Box width={"100%"} justifyContent={"center"} padding={16}>
          No items (Add some items)
        </Box>
      )}
      <ShipmentItemForm shipmentId={id ?? ""} shipmentItem={edit} onClosed={() => setEdit(undefined)} {...disclosure} />
    </VStack>
  );
}
