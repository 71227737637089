import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Icon, useDisclosure } from "@chakra-ui/react";
import { FragmentType, useFragment } from "__generated__";
import { SHIPMENT_ENTITY } from "./query.graphql";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

interface Props {
  shipment: FragmentType<typeof SHIPMENT_ENTITY>;
}
export default function ShipmentNote({ shipment }: Props) {
  const { attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const { isOpen, onToggle } = useDisclosure();

  if (!attributes?.note) return null;

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"start"} width={"100%"}>
      <Button variant={"outline"} size={"sm"} onClick={onToggle} display={"flex"} alignItems={"center"} mb={2}>
        {isOpen ? "Hide" : "View"} Note &nbsp; <Icon as={isOpen ? MdArrowUpward : MdArrowDownward} />
      </Button>
      {isOpen && (
        <Alert status="info" variant="subtle" alignItems={"start"}>
          <AlertIcon my={2} />
          <Box textAlign="start">
            <AlertTitle>Note</AlertTitle>
            <AlertDescription whiteSpace={"pre-wrap"}>{attributes?.note}</AlertDescription>
          </Box>
        </Alert>
      )}
    </Box>
  );
}
