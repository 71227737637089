import { createContext, ReactNode } from "react";
import { gql } from "__generated__";
import { ConfigurationQuery } from "__generated__/graphql";
import { useQuery } from "@apollo/client";

const QUERY = gql(`
	query Configuration {
		company {
			data {
				attributes {
					name
					email
					phone
					address
					invoice_disclaimer
				}
			}
		}
		config {
			data {
				attributes {
					FRONTEND_URL
					FLW_PUBLIC
				}
			}
		}
	}
`);

interface Configuration {
  company: Partial<NonNullable<NonNullable<ConfigurationQuery["company"]>["data"]>["attributes"]>;
  config: Partial<NonNullable<NonNullable<ConfigurationQuery["config"]>["data"]>["attributes"]>;
}

export const ConfigurationContext = createContext<Configuration>({ config: {}, company: {} });

export default function ConfigurationProvider({ children }: { children: ReactNode }) {
  const { data } = useQuery(QUERY);
  const config = data?.config?.data?.attributes ?? {};
  const company = data?.company?.data?.attributes ?? {};

  return <ConfigurationContext.Provider value={{ config, company }}>{children}</ConfigurationContext.Provider>;
}
