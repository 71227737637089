import { useQuery } from "@apollo/client";
import Layout from "components/Layout";
import { QUERY_ONE } from "components/shipment/query.graphql";
import { useParams } from "react-router-dom";
import ShipmentRow from "components/shipment/ShipmentRow";
import { Divider, Text, VStack } from "@chakra-ui/react";
import numeral from "numeral";
import ShipmentInvoiceButtons from "components/shipment/ShipmentInvoiceButtons";
import { SHIPMENT_NUMBER_FORMAT } from "constant";
import ShipmentItems from "components/shipment/ShipmentItems";
import ShipmentNote from "components/shipment/ShipmentNote";

export default function ShipmentDetail() {
  const { id } = useParams<{ id: string }>();
  const { data } = useQuery(QUERY_ONE, {
    variables: { id: id ?? "" },
  });

  const shipment = data?.shipment?.data;

  if (!shipment) return null;

  return (
    <Layout
      headerBottom={
        <>
          <Divider my={4} colorScheme={"purple"} />
          <Text textAlign={"start"} fontSize={"3xl"}>
            Shipment <em>#{numeral(shipment?.id).format(SHIPMENT_NUMBER_FORMAT)}</em>
          </Text>
        </>
      }
    >
      <VStack w={{ base: "100%", lg: "800px" }} spacing={"16px"} pb={"200px"}>
        <ShipmentInvoiceButtons shipment={shipment} />
        <ShipmentRow shipment={shipment} />
        <ShipmentNote shipment={shipment} />
        <ShipmentItems shipment={shipment} />
      </VStack>
    </Layout>
  );
}
