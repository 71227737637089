import { useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputProps,
  useColorModeValue,
  InputRightElement,
} from "@chakra-ui/react";
import { MdCancel } from "react-icons/md";

interface Props extends InputProps {
  onClear?: () => void;
}
export function SearchInput({ onClear, ...inputProps }: Props) {
  const [clear, setClear] = useState(false);
  const color = useColorModeValue("balck", "white");

  return (
    <InputGroup borderColor={color} {...inputProps}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <Input
        borderColor={color}
        {...inputProps}
        onChange={(e) => {
          if (e.target.value.length === 0) setClear(false);
          else setClear(true);
          inputProps.onChange?.(e);
        }}
      />
      {clear && onClear && (
        <InputRightElement>
          <MdCancel onClick={onClear} cursor={"pointer"} />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
