import { useQuery } from "@apollo/client";
import { Text, Flex, Divider, Input, useToast, Box } from "@chakra-ui/react";
import Layout from "components/Layout";
import ShipmentRow, { ShipmentRowCheckbox } from "components/shipment/ShipmentRow";
import { endOfMonth, format, parse, startOfMonth } from "date-fns";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import usePagination from "utils/hooks/usePagination";
import ShipmentForm from "components/shipment/ShipmentForm";
import { SORT, QUERY_MANY, SHIPMENT_ENTITY } from "components/shipment/query.graphql";
import { lowerCase, orderBy, uniqBy } from "lodash";
import InfiniteList from "components/InfiniteList";
import numeral from "numeral";
import { FragmentType } from "__generated__";
import useShipmentMutations from "utils/hooks/useShipmentMutations";
import { getGqlError } from "utils/gpq-helpers";
import { SearchInput } from "components/SearchInput";
import { ShipmentFiltersInput } from "__generated__/graphql";
import useDebouncedState from "utils/hooks/useDebouncedState";
import useCurrentUser from "utils/hooks/useCurrentUser";
import { STAFF_ROLES } from "constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BatchpayButton from "components/invoice/BatchpayButton";

const thisMonth = format(new Date(), "LLyy");

export default function Shipments() {
  const [urlParams, setUrlParams] = useSearchParams();
  const monthFilter = useMemo(() => {
    const month = parse(urlParams.get("m") || thisMonth, "LLyy", new Date());
    return {
      createdAt: {
        gte: startOfMonth(month),
        lte: endOfMonth(month),
      },
    };
  }, [urlParams]);
  const user = useCurrentUser();
  const isStaff = STAFF_ROLES.includes(user?.role.name ?? "");
  const nonStaffFilter = !isStaff ? { invoice: { id: { not: null } } } : {};
  const { pagination, loadMore, reset: resetPagination } = usePagination({ page: 1, pageSize: 10 });
  const [filters, setFilters] = useState<ShipmentFiltersInput>({
    ...nonStaffFilter,
    ...monthFilter,
  });
  const [search, setSearch, searchValue] = useDebouncedState<string>(undefined, 1000);
  const { data, loading: queryLoading } = useQuery(QUERY_MANY, {
    variables: { pagination, filters, sort: SORT },
    fetchPolicy: "network-only",
  });
  const [list, setList] = useState(data?.shipments?.data ?? []);
  const [editShipment, setEditShipment] = useState<FragmentType<typeof SHIPMENT_ENTITY>>();
  const {
    delete: { deleteShipment, deleting },
  } = useShipmentMutations({
    pagination,
    filters,
    sort: SORT,
  });
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (data?.shipments?.data) {
      const newData = data?.shipments?.data;
      setList((current) => orderBy(uniqBy([...current, ...newData], "id"), (v) => numeral(v.id).value(), "desc"));
    }
  }, [data]);

  useEffect(() => {
    if (typeof search !== "undefined") {
      resetPagination();
      setList([]);
      setFilters((f) => ({
        ...f,
        or: [
          { from: { containsi: search } },
          { to: { containsi: search } },
          {
            receiver: {
              or: [
                { firstName: { containsi: search } },
                { lastName: { containsi: search } },
                { email: { containsi: search } },
                { phone: { containsi: search } },
                { alias: { containsi: search } },
              ],
            },
          },
          {
            alien: {
              or: [
                { firstName: { containsi: search } },
                { lastName: { containsi: search } },
                { phone: { containsi: search } },
              ],
            },
          },
          {
            invoice: {
              or: [
                { code: { containsi: search } },
                ...(lowerCase(search) === "paid" ? [{ paymentStatus: { eq: true } }] : []),
                ...(lowerCase(search) === "unpaid"
                  ? [{ or: [{ paymentStatus: { eq: false } }, { paymentStatus: { eq: null } }] }]
                  : []),
              ],
            },
          },
        ],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onChangeMonth = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (!value) return;
    resetPagination();
    setList([]);
    setFilters((c) => ({
      ...c,
      createdAt: {
        gte: startOfMonth(parse(value, "yyyy-MM", new Date())),
        lte: endOfMonth(parse(value, "yyyy-MM", new Date())),
      },
    }));
    setUrlParams({ m: format(parse(value, "yyyy-MM", new Date()), "LLyy") });
  };

  const reset = () => {
    setList([]);
    resetPagination();
    setFilters({ ...nonStaffFilter, ...monthFilter });
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteShipment({
        variables: {
          id: id ?? "",
        },
      });
      setList((current) => current.filter((item) => item?.id !== id));
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  return (
    <Layout
      headerBottom={
        <>
          <Divider my={4} />
          <Flex width={"100%"} justifyContent={"space-between"} alignItems={"center"} mt={4} flexWrap={"wrap"}>
            <Text fontSize={"3xl"} mb={2}>
              Shipments
            </Text>
            <Input
              type="month"
              maxW="160px"
              size={"sm"}
              onChange={onChangeMonth}
              value={format(filters?.createdAt?.gte, "yyyy-MM")}
              max={format(new Date(), "yyyy-MM")}
            />
          </Flex>
        </>
      }
    >
      {isStaff && (
        <ShipmentForm
          open={location.pathname === "/shipments/new"}
          pagination={pagination}
          filters={filters}
          onSave={reset}
          edit={editShipment}
          onClosed={() => {
            setEditShipment(undefined);
            navigate("/shipments");
          }}
        />
      )}
      <Box w={{ base: "100%", lg: "800px" }}>
        <InfiniteList
          headerComponent={
            <SearchInput
              size={"lg"}
              placeholder="Search by location, recevier etc..."
              value={searchValue}
              onChange={(e) => setSearch(e.target.value)}
              onClear={() => setSearch("")}
            />
          }
          list={list}
          threshhold={2}
          isLoading={queryLoading}
          renderItem={(item) => (
            <Box position={"relative"}>
              <ShipmentRowCheckbox shipment={item} />
              <ShipmentRow
                editable={isStaff}
                deleting={deleting}
                shipment={item}
                onEdit={setEditShipment}
                onDelete={handleDelete}
              />
            </Box>
          )}
          onReachEnd={() => loadMore(data?.shipments?.meta.pagination.pageCount ?? 0)}
          emptyComponent={<Text fontSize={"4xl"}>No shipment found</Text>}
        />
      </Box>
      <BatchpayButton onSuccess={reset} />
    </Layout>
  );
}
