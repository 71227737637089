import { useRef, useEffect } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { useIntersectionObserver } from "usehooks-ts";

interface Props extends BoxProps {
  onVisible: () => void;
}

export default function VisibleBox({ onVisible, ...boxProps }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {
    threshold: 0.1,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && onVisible) {
      onVisible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return <Box ref={ref} {...boxProps} />;
}
