import { Box, Button, Divider, Input, VStack } from "@chakra-ui/react";
import useAliens from "utils/hooks/useAliens";
import { Select } from "chakra-react-select";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import useDebouncedState from "utils/hooks/useDebouncedState";
import { Alien } from "types";

interface Props {
  initalValue?: Alien;
  onChange?: (value: Alien) => void;
  onSelect?: (value: string) => void;
}
export default function AlienFields({ initalValue, onChange, onSelect }: Props) {
  const [newAlienInput, setNewAlienInput] = useState({
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [newAlien, setNewAlien] = useState(false);
  const [aliensExist, setAliensExist] = useState(false);
  const [alienFilter, setAlienFilter] = useDebouncedState("", 1000);
  const { data: aliens, loading: isLoadingAliens } = useAliens({
    filters: {
      or: [
        { firstName: { containsi: alienFilter } },
        { lastName: { containsi: alienFilter } },
        { phone: { containsi: alienFilter } },
      ],
    },
  });

  const alienOptions = useMemo(
    () =>
      aliens.map(({ id, attributes }) => ({
        value: id ?? "",
        label: `${attributes?.firstName} ${attributes?.lastName} - ${attributes?.phone}`,
      })),
    [aliens]
  );

  useEffect(() => {
    if (!isEmpty(alienOptions)) setAliensExist(true);
  }, [alienOptions]);

  const handleChange = (key: keyof Alien) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = { ...newAlienInput, [key]: e.target.value };
    setNewAlienInput(newValue);
    onChange?.(newValue);
  };

  return (
    <Box>
      <VStack w={"100%"} alignItems={"flex-start"}>
        {aliensExist && !newAlien && (
          <>
            <Select
              placeholder="Select a previous alien"
              options={alienOptions}
              onChange={(e) => {
                if (onSelect && e) onSelect(e.value);
              }}
              isLoading={isLoadingAliens}
              onInputChange={setAlienFilter}
              chakraStyles={{ container: (provided) => ({ ...provided, width: "100%" }) }}
              {...(initalValue && {
                defaultInputValue: `${initalValue.firstName} ${initalValue.lastName} - ${initalValue.phone}`,
              })}
            />
            <Button onClick={() => setNewAlien(true)}>Add new alien</Button>
            <Divider />
          </>
        )}
        {(newAlien || isEmpty(alienOptions)) && (
          <VStack w={"100%"} ps={4} borderStartColor={"cyan"} borderStartWidth={2}>
            <Input placeholder="First Name" value={newAlienInput.firstName} onChange={handleChange("firstName")} />
            <Input placeholder="Last Name" value={newAlienInput.lastName} onChange={handleChange("lastName")} />
            <Input placeholder="Phone" value={newAlienInput.phone} onChange={handleChange("phone")} max={11} />
            {!isEmpty(alienOptions) && (
              <Box w={"100%"}>
                <Button
                  onClick={() => {
                    setNewAlien(false);
                    setNewAlienInput({
                      firstName: "",
                      lastName: "",
                      phone: "",
                    });
                  }}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </VStack>
        )}
      </VStack>
    </Box>
  );
}
