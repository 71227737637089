import { FragmentType, gql, useFragment } from "__generated__";
import { SHIPMENT_ENTITY } from "./query.graphql";
import { Button, ButtonGroup, useToast } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import useCurrentUser from "utils/hooks/useCurrentUser";
import { generateInvoiceCode } from "utils/functions";
import { getGqlError } from "utils/gpq-helpers";
import { isEmpty, toLower } from "lodash";
import Pickup from "./Pickup";
import { useNavigate } from "react-router-dom";
import { STAFF_ROLES } from "constant";
import ValidatePaymentButton from "components/ValidatePaymentButton";
import { MdSms } from "react-icons/md";
import GrabPickupCodeButton from "components/GrabPickupCodeButton";

interface Props {
  shipment: FragmentType<typeof SHIPMENT_ENTITY>;
}

const createInvoiceMutation = gql(`
	mutation CreateInvoice($input: InvoiceInput!) {
		createInvoiceWithNotice(data: $input) {
      ...InvoiceFragment
		}
	}
`);

const sendPickupMutation = gql(`
	mutation sendPickupCode($id: ID!) {
		sendPickupCode(id: $id) {
      data
    }
	}
`);

export default function InvoiceButtons({ shipment }: Props) {
  const currentUser = useCurrentUser();
  const isStaff = STAFF_ROLES.includes(currentUser?.role.name ?? "");
  const isDirector = toLower(String(currentUser?.role.name)) === "director";

  const toast = useToast();
  const navigate = useNavigate();
  const { id, attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const [createInvoice, { loading }] = useMutation(createInvoiceMutation);
  const [sendSMS, { loading: sendingSMS }] = useMutation(sendPickupMutation);

  const handleCreateInvoice = async () => {
    try {
      await createInvoice({
        variables: {
          input: {
            owner: currentUser?.id,
            code: generateInvoiceCode(),
            receiver: attributes?.receiver?.data?.id,
            shipment: id,
          },
        },
      });
      toast({
        title: "An invoice has been created and attached to this shipment",
        status: "success",
      });
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  const handleResendSMS = async () => {
    try {
      await sendSMS({
        variables: {
          id: id ?? "",
        },
      });
      toast({
        title: "SMS sent successfully",
        status: "success",
      });
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  const hasInvoice = !isEmpty(attributes?.invoice?.data);
  const canGenerateInvoice = !hasInvoice && !loading && !isEmpty(attributes?.shipment_items?.data);
  const hasPaid = !!attributes?.invoice?.data?.attributes?.paymentStatus;
  const isFulfilled = !!attributes?.fulfilment?.data;

  const paymentGatewayResponse = attributes?.invoice?.data?.attributes?.paymentGatewayResponse;

  return (
    <ButtonGroup w={"100%"} overflowX={"scroll"} size={"sm"}>
      {isStaff && !hasInvoice && (
        <Button colorScheme="green" isDisabled={!canGenerateInvoice} onClick={handleCreateInvoice} isLoading={loading}>
          Generate Invoice
        </Button>
      )}
      {hasInvoice && (
        <Button
          colorScheme="purple"
          isDisabled={!hasInvoice}
          onClick={() => navigate(`/invoices/${attributes?.invoice?.data?.attributes?.code}`)}
        >
          Invoice
        </Button>
      )}
      {isStaff && hasPaid && !isFulfilled && <Pickup shipment={shipment} />}
      {isDirector && paymentGatewayResponse && (
        <ValidatePaymentButton paymentGatewayResponse={paymentGatewayResponse} />
      )}
      {isStaff && hasInvoice && hasPaid && !isFulfilled && (
        <Button
          colorScheme="orange"
          onClick={handleResendSMS}
          isLoading={sendingSMS}
          aria-label="Resend Pickup code"
          title="Resend Pickup code"
        >
          <MdSms /> Resend
        </Button>
      )}
      {isDirector && hasPaid && !isFulfilled && <GrabPickupCodeButton id={id ?? ""} />}
    </ButtonGroup>
  );
}
