import { Box, Button, FormLabel, HStack, PinInput, PinInputField, useDisclosure, useToast } from "@chakra-ui/react";
import Drawer from "components/Drawer";
import { SHIPMENT_ENTITY } from "./query.graphql";
import { FragmentType, gql, useFragment } from "__generated__";
import { useState } from "react";
import { getGqlError } from "utils/gpq-helpers";
import { useMutation } from "@apollo/client";
import useCurrentUser from "utils/hooks/useCurrentUser";
import { MdHail } from "react-icons/md";

const confirmPickupMutation = gql(`
  mutation Pickup($input: FulfilInput!) {
    fulfill(data: $input) {
      data {
        id
        ...ShipmentFragment
      }
    }
  }
`);

interface Props {
  shipment: FragmentType<typeof SHIPMENT_ENTITY>;
}
export default function Pickup({ shipment }: Props) {
  const currentUser = useCurrentUser();
  const { id, attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [code, setCode] = useState("");
  const [confirmPickup, { loading }] = useMutation(confirmPickupMutation);
  const toast = useToast();

  const hasPaid = !!attributes?.invoice?.data?.attributes?.paymentStatus;
  const isFulfilled = !!attributes?.fulfilment?.data;

  const handleSubmit = async () => {
    try {
      await confirmPickup({
        variables: {
          input: {
            codeGiven: code,
            shipmentId: id ?? "",
            fulfilledBy: currentUser?.id ?? "",
          },
        },
      });
      toast({
        title: "Pickup code confirmed. Please give the package to the customer.",
        status: "success",
      });
      onClose();
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  return (
    <>
      <Button colorScheme="blue" isDisabled={!hasPaid || isFulfilled} onClick={onOpen}>
        <MdHail /> Pickup
      </Button>
      <Drawer
        title="Shipment Pickup"
        isOpen={isOpen}
        onClose={onClose}
        size={"md"}
        content={
          <Box>
            <FormLabel htmlFor="code">Pickup Code</FormLabel>
            <HStack>
              <PinInput type="alphanumeric" id="code" value={code} onChange={(v) => setCode(v.toUpperCase())}>
                {new Array(10).fill(0).map((_, i) => (
                  <PinInputField key={i} />
                ))}
              </PinInput>
            </HStack>
          </Box>
        }
        footer={
          <>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="purple" isDisabled={code.length !== 10} isLoading={loading} onClick={handleSubmit}>
              Submit
            </Button>
          </>
        }
      />
    </>
  );
}
