import { useLoaderData } from "react-router-dom";
import { Role } from "types";

interface CurrentUser {
	id: string;
	email: string;
	role: { name: Role };
}
export default function useCurrentUser() {
	const loaderData = useLoaderData();
	return loaderData as CurrentUser | null;
}