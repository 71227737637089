import { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { gql } from "__generated__";
import { CreateBasketMutation } from "__generated__/graphql";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { isEmpty } from "lodash";
import { getGqlError } from "utils/gpq-helpers";
import useBatchPayment from "utils/hooks/useBatchPayment";
import useConfiguration from "utils/hooks/useConfiguration";
import useOmic from "utils/hooks/useOmic";

const CREATE_BASKET_MUTATION = gql(`
	mutation CreateBasket($input: CreateBasketInput!) {
		createBasket(data: $input) {
			uid,
			total,
			customer {
				name,
				email,
				phone_number
			}
		}
	}
`);

interface Props {
  onSuccess?: () => void;
}
export default function BatchpayButton({ onSuccess }: Props) {
  const [basket, setBasket] = useState<CreateBasketMutation | null>();
  const toast = useToast();
  const { invoices: batchInvoices, processing, toggleProcessing, toggleBatchPay, clearBatchPayInvoices } = useOmic();
  const [createBasket] = useMutation(CREATE_BASKET_MUTATION);

  if (isEmpty(batchInvoices)) return null;
  const btnLabel = batchInvoices.length > 1 ? `Process ${batchInvoices.length} Invoices` : "Add More Invoices";

  const handleBatchPay = async () => {
    toggleProcessing();
    try {
      const { data } = await createBasket({
        variables: {
          input: {
            basket: batchInvoices.map((invoice) => invoice),
          },
        },
      });
      setBasket(data);
    } catch (error: any) {
      toast({
        title: "Error",
        description: getGqlError(error),
        status: "error",
      });
      toggleProcessing();
    }
  };

  return (
    <>
      {!basket && (
        <Button
          position={"fixed"}
          bottom={"120px"}
          colorScheme="yellow"
          size={"lg"}
          isDisabled={batchInvoices.length < 2 || processing}
          onClick={handleBatchPay}
          isLoading={processing}
        >
          {btnLabel}
        </Button>
      )}
      {basket && (
        <Pay
          tx_ref={basket?.createBasket?.uid ?? ""}
          amount={basket?.createBasket?.total ?? 0}
          customer={{
            email: basket?.createBasket?.customer?.email ?? "",
            phone_number: basket?.createBasket?.customer?.phone_number ?? "",
            name: basket?.createBasket?.customer?.name ?? "",
          }}
          onClose={() => {
            setBasket(null);
            toggleProcessing();
            clearBatchPayInvoices();
            toggleBatchPay();
          }}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

interface PayProps {
  tx_ref: string;
  amount: number;
  customer: { email: string; phone_number: string; name: string };
  onClose: () => void;
  onSuccess?: () => void;
}
function Pay({ tx_ref, amount, customer, onClose, onSuccess }: PayProps) {
  const [disableButton, setDisableButton] = useState(false);
  const cancelRef = useRef(null);
  const toast = useToast();
  const { config, company } = useConfiguration();
  const {
    validate: { validatePayment },
  } = useBatchPayment({
    refetchQueries: ["Shipments"],
  });
  const flutterwavePayment = useFlutterwave({
    public_key: config?.FLW_PUBLIC ?? "",
    tx_ref,
    amount,
    currency: "NGN",
    payment_options: "card,banktransfer,mobilemoney,ussd",
    customer,
    customizations: {
      title: company?.name ?? process.env.REACT_APP_NAME ?? "",
      description: `Payment for invoice basket ${tx_ref}`,
      logo: "",
    },
  });

  const handlePayment = () => {
    setDisableButton(true);
    flutterwavePayment({
      callback: async (response) => {
        await validatePayment({
          variables: {
            input: {
              status: response?.status,
              tx_ref: response?.tx_ref,
              transaction_id: String(response?.transaction_id),
            },
          },
        });
        toast({
          title: "Payment successful. Your pickup ID has been sent to your email/phone.",
          status: "success",
          position: "top",
          duration: 10000,
        });
        onClose();
        onSuccess?.();
        closePaymentModal();
      },
      onClose: onClose,
    });
  };

  return (
    <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Batch Pay</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>Hit the "Pay Now" button to complete payment for the selected shipments.</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="green" ml={3} onClick={handlePayment} isDisabled={disableButton}>
            Pay Now
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
