import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "pages/index";
import auth from "utils/auth-check";
import Shipments from "pages/shipments";
import Invoices from "pages/invoices";
import ShipmentDetail from "pages/shipments/detail";
import InvoiceDetail from "pages/invoices/detail";
import ForgotPassword from "pages/forgotPassword";
import ResetPassword from "pages/resetPassword";

const router = createBrowserRouter([
  {
    path: "/invoices",
    children: [
      {
        index: true,
        element: <Invoices />,
        loader: auth({ signedOutRedirect: "/", roles: ["Director"] }),
      },
      {
        path: ":id",
        element: <InvoiceDetail />,
        loader: auth({}),
      },
    ],
  },
  {
    path: "/shipments",
    children: [
      {
        index: true,
        element: <Shipments />,
        loader: auth({ signedOutRedirect: "/" }),
      },
      {
        path: "new",
        element: <Shipments />,
        loader: auth({ signedOutRedirect: "/" }),
      },
      {
        path: ":id",
        element: <ShipmentDetail />,
        loader: auth({ signedOutRedirect: "/" }),
      },
    ],
  },
  {
    path: "/",
    element: <Main />,
    loader: auth({ signedInRedirect: "/shipments" }),
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    loader: auth({ signedInRedirect: "/shipments" }),
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    loader: auth({ signedInRedirect: "/shipments" }),
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
