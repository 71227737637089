import { useState } from "react";

interface Props {
	page: number;
	pageSize: number;
}

export default function usePagination({ page, pageSize, }: Props) {
	const [pagination, setPagination] = useState({ page, pageSize });

	const loadMore = (pageCount: number) => {
		if (!pageCount) return;
		if (pagination.page >= pageCount) return;

		setPagination({ page: pagination.page + 1, pageSize: pagination.pageSize });
	}

	const reset = () => {
		setPagination({ page, pageSize });
	}

	return {
		pagination,
		loadMore,
		reset
	}
}