import { FragmentType, useFragment } from "__generated__";
import { INVOICE_ENTITY } from "./query.graphql";
import { Badge, Box, Button, ButtonGroup, Divider, HStack, IconButton, Text, VStack, useToast } from "@chakra-ui/react";
import { SHIPMENT_ENTITY, SHIPMENT_ITEMS_ENTITY } from "components/shipment/query.graphql";
import { useEffect, useState, MouseEvent } from "react";
import { sum, values } from "lodash";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";

interface Props {
  invoice: FragmentType<typeof INVOICE_ENTITY>;
  deleting?: boolean;
  onDelete?: (arg: string) => void;
}

export default function InvoiceRow({ invoice, deleting, onDelete }: Props) {
  const { id, attributes } = useFragment(INVOICE_ENTITY, invoice);
  const navigate = useNavigate();
  const toast = useToast();

  const shipment = attributes?.shipment?.data;
  const hasPaid = !!attributes?.paymentStatus;

  const handleClick = () => {
    if (!shipment?.id) return;
    navigate(`/invoices/${attributes?.code}`);
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (toast.isActive(`delete-${id}`)) return;
    toast({
      id: `delete-${id}`,
      render: ({ onClose }) => (
        <Box color="white" p={3} bg="red.500" rounded={4}>
          <Text>Are you sure you want to delete this Invoice?</Text>
          <ButtonGroup w={"100%"} justifyContent={"end"} mt={3} spacing={3}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme="red"
              isLoading={deleting}
              isDisabled={deleting}
              onClick={() => {
                onDelete?.(id ?? "");
                onClose();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </Box>
      ),
    });
  };

  return (
    <VStack
      width={{ lg: 800 }}
      bg={"gray.700"}
      textColor={"white"}
      rounded={4}
      p={4}
      cursor={"pointer"}
      position={"relative"}
      onClick={handleClick}
    >
      <HStack justifyContent={"space-between"} w={"100%"}>
        <Text fontSize={"2xl"}>#{attributes?.code}</Text>
        <Badge fontSize={"xl"} colorScheme={hasPaid ? "green" : "red"}>
          {hasPaid ? "PAID" : "UNPAID"}
        </Badge>
      </HStack>
      <Divider />
      <HStack justifyContent={"space-between"} w={"100%"}>
        {shipment && <Receiver shipment={shipment} />}
        {shipment && <Amount shipment={shipment} />}
      </HStack>
      {!hasPaid && (
        <HStack my={2} position={"absolute"} top={-6} right={4}>
          <IconButton
            icon={<MdDelete />}
            aria-label="Delete Invoice"
            colorScheme="red"
            size={"sm"}
            onClick={handleDelete}
          />
        </HStack>
      )}
    </VStack>
  );
}

function Receiver({ shipment }: { shipment: FragmentType<typeof SHIPMENT_ENTITY> }) {
  const { attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const isAlien = attributes?.receiver?.data?.attributes?.firstName?.toLowerCase().includes("alien");
  const receiverName = isAlien
    ? `${attributes?.alien?.data?.attributes?.firstName} ${attributes?.alien?.data?.attributes?.lastName} (ALIEN)`
    : `${attributes?.receiver?.data?.attributes?.firstName} ${attributes?.receiver?.data?.attributes?.lastName}`;
  return <Text fontSize={"xl"}>Invoiced To: {receiverName}</Text>;
}

function Amount({ shipment }: { shipment: FragmentType<typeof SHIPMENT_ENTITY> }) {
  const { attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const [total, setTotal] = useState<Record<string, number>>({});
  const sumTotal = sum(values(total));

  const shipmentItems = attributes?.shipment_items?.data;

  return (
    <>
      <Text fontSize={"xl"}>N{numeral(sumTotal).format("0,00.00")}</Text>
      {shipmentItems?.map((item) => (
        <ShipmentItem
          key={`shipment-item-${item?.id}`}
          item={item}
          onSumChange={(v) => setTotal((c) => ({ ...c, [item.id ?? ""]: v }))}
        />
      ))}
    </>
  );
}

function ShipmentItem({
  item,
  onSumChange,
}: {
  item: FragmentType<typeof SHIPMENT_ITEMS_ENTITY>;
  onSumChange: (sum: number) => void;
}) {
  const { attributes } = useFragment(SHIPMENT_ITEMS_ENTITY, item);
  const amount = (attributes?.quantity ?? 0) * (attributes?.unit_price ?? 0);
  useEffect(() => {
    onSumChange(amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);
  return null;
}
