import { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { getGqlError } from "utils/gpq-helpers";
import { gql } from "__generated__";
import { useMutation } from "@apollo/client";
import { MdPassword } from "react-icons/md";
import { useCopyToClipboard } from "usehooks-ts";

const grabPickupCodeMutation = gql(`
	mutation GrabPickupCode($id: ID!) {
		grabPickupCode(id: $id) {
      pickup_code
    }
	}
`);

interface Props {
  id: string;
}

export default function GrabPickupCodeButton({ id }: Props) {
  const [code, setCode] = useState<string>();
  const cancelRef = useRef(null);
  const [grabPickupCode, { loading }] = useMutation(grabPickupCodeMutation);
  const toast = useToast();
  const [, copy] = useCopyToClipboard();

  const handleClick = async () => {
    try {
      const { data } = await grabPickupCode({
        variables: {
          id,
        },
      });
      const pickupCode = data?.grabPickupCode?.pickup_code;
      if (pickupCode) setCode(pickupCode);
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  const onClose = () => setCode(undefined);

  const handleCopy = () => {
    if (!code) return;
    copy(code);
    toast({
      title: "Pickup code copied to clipboard",
      status: "success",
    });
    onClose();
  };

  return (
    <>
      <Button
        colorScheme="yellow"
        onClick={handleClick}
        isLoading={loading}
        aria-label="Grab pickup code"
        title="Grab pickup code"
      >
        <MdPassword fontSize={24} />
      </Button>
      <AlertDialog isOpen={!!code} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody fontSize={"4xl"} textAlign={"center"} py={12}>
              {code}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Dismiss
              </Button>
              <Button colorScheme="green" onClick={handleCopy} ml={3}>
                Copy
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
