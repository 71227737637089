import { Button, useToast } from "@chakra-ui/react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { CustomInvoiceEntity } from "__generated__/graphql";
import { useState } from "react";
import useConfiguration from "utils/hooks/useConfiguration";
import usePayment from "utils/hooks/usePayment";

interface Props {
  invoice: CustomInvoiceEntity;
  total: number;
}

export default function PayNowButton({ invoice, total }: Props) {
  const { paymentStatus, shipment } = invoice;
  const [disableButton, setDisableButton] = useState(false);
  const {
    validate: { validatePayment },
  } = usePayment({
    refetchQueries: ["InvoiceByCode"],
  });
  const { config, company } = useConfiguration();

  const toast = useToast();

  const isAlien = shipment?.receiver?.role?.name === "Alien";
  const phone = !!shipment?.alien ? shipment.alien?.phone : shipment?.receiver?.phone;
  const customerName = isAlien
    ? `${shipment?.alien?.firstName} ${shipment?.alien?.lastName}`
    : `${shipment?.receiver?.firstName} ${shipment?.receiver?.lastName}`;

  const flutterwavePayment = useFlutterwave({
    public_key: config?.FLW_PUBLIC ?? "",
    tx_ref: invoice.code,
    amount: total,
    currency: "NGN",
    payment_options: "card,banktransfer,mobilemoney,ussd",
    customer: {
      email: shipment?.receiver?.email ?? "",
      phone_number: phone ?? "",
      name: customerName,
    },
    customizations: {
      title: company?.name ?? process.env.REACT_APP_NAME ?? "",
      description: `Payment for invoice ${invoice.code}`,
      logo: "",
    },
  });

  const paid = paymentStatus;

  if (paid || !shipment) return null;

  const handlePayment = () => {
    setDisableButton(true);
    flutterwavePayment({
      callback: async (response) => {
        await validatePayment({
          variables: {
            input: {
              status: response?.status,
              tx_ref: response?.tx_ref,
              transaction_id: String(response?.transaction_id),
            },
          },
        });
        toast({
          title: "Payment successful. Your pickup ID has been sent to your email/phone.",
          status: "success",
          position: "top",
          duration: 10000,
        });
        closePaymentModal();
      },
      onClose: () => {
        setDisableButton(false);
      },
    });
  };

  return (
    <>
      <Button colorScheme="green" onClick={handlePayment} isDisabled={disableButton}>
        Pay Now
      </Button>
    </>
  );
}
