import { MutationHookOptions, useMutation } from "@apollo/client";
import { gql } from "__generated__";

const validatePaymentMutation = gql(`
  mutation ValidatePayment($input: ValidatePaymentInput!) {
    validatePayment(data: $input) {
      data {
        id
      }
      message
    }
  }
`);

export default function usePayment(options?: MutationHookOptions) {
  const [validatePayment, { loading: validating }] = useMutation(validatePaymentMutation, { ...options });

  return {
    validate: { validatePayment, validating },
  };
}
