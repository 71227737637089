import { ComponentProps, ReactNode } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
} from "@chakra-ui/react";

interface Props extends Omit<ComponentProps<typeof Drawer>, "children"> {
  title: string;
  content: ReactNode;
  footer?: ReactNode;
}

export default function DrawerComponent({
  title,
  content,
  footer,
  ...props
}: Props) {
  return (
    <Drawer {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth={"1px"}>{title}</DrawerHeader>
        <DrawerBody>
          <Stack spacing="24px">{content}</Stack>
        </DrawerBody>
        {footer && <DrawerFooter borderTopWidth="1px">{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
}
