import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { InputMaybe, ShipmentFiltersInput } from "__generated__/graphql";
import { uniq } from "lodash";

const query = gql(`
		query ShipmentLocations($filters: ShipmentFiltersInput, $pagination: PaginationArg) {
			shipments(filters: $filters, pagination: $pagination) {
				data {
					attributes {
						from
						to
					}
				}
			}
		}
	`);

interface Props {
	filters?: InputMaybe<ShipmentFiltersInput>;
}
export default function useShipmentLocations({ filters }: Props) {
	const { data, loading } = useQuery(query, {
		variables: {
			filters,
			pagination: {
				page: 1,
				pageSize: 10,
			}
		},
	});
	const from = uniq((data?.shipments?.data ?? []).map((shipment) => shipment?.attributes?.["from"] ?? ""));
	const to = uniq((data?.shipments?.data ?? []).map((shipment) => shipment?.attributes?.["to"] ?? ""));

	return { data: { from, to }, loading };
}
