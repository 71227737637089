import { useQuery } from "@apollo/client";
import { gql, FragmentType, useFragment } from "__generated__";
import { InputMaybe, AlienFiltersInput } from "__generated__/graphql";

const ALIEN_FRAGMENT = gql(`
	fragment AlienFragment on AlienEntity {
		id
		attributes {
			firstName
			lastName
			phone
		}
	}			
`);

export type ALIENS_TYPE = FragmentType<typeof ALIEN_FRAGMENT>;

const query = gql(`
		query Aliens($filters: AlienFiltersInput, $pagination: PaginationArg) {
			aliens(filters: $filters, pagination: $pagination) {
				data {
					...AlienFragment
				}
			}
		}
	`);

interface Props {
	filters?: InputMaybe<AlienFiltersInput>;
}
export default function useAliens({ filters }: Props) {
	const { data, loading } = useQuery(query, {
		variables: {
			filters,
			pagination: {
				page: 1,
				pageSize: 10,
			}
		},
	});
	const result = data?.aliens?.data;
	return { data: useFragment(ALIEN_FRAGMENT, result) ?? [], loading };
}
