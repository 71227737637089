import { MutationHookOptions, useMutation } from "@apollo/client";
import { gql } from "__generated__";

const BATCH_PAYMENT_VALIDATE = gql(`
  mutation ValidateBatchPayment($input: ValidatePaymentInput!) {
    validateBasketPayment(data: $input) {
      message
    }
  }
`);

export default function useBatchPayment(options?: MutationHookOptions) {
  const [validatePayment, { loading: validating }] = useMutation(BATCH_PAYMENT_VALIDATE, { ...options });

  return {
    validate: { validatePayment, validating },
  };
}
