import { ChakraProvider, StyleFunctionProps, extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import Router from "Router";
import ConfigurationProvider from "components/providers/Configuration";
import OmicContextProvider from "components/providers/Omic";

const appTheme = extendTheme({
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode("white", "gray.800")(props),
      },
    }),
  },
});

export const App = () => (
  <ChakraProvider theme={appTheme}>
    <ConfigurationProvider>
      <OmicContextProvider>
        <Router />
      </OmicContextProvider>
    </ConfigurationProvider>
  </ChakraProvider>
);
