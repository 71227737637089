import { Img, useColorModeValue } from "@chakra-ui/react";

interface Props {
  width?: number;
}

export default function Logo({ width = 180 }: Props) {
  const logoFilter = useColorModeValue("", "brightness(0) invert(1)");

  return <Img src={"/logo.png"} alt={process.env.REACT_APP_NAME} width={width} filter={logoFilter} />;
}
