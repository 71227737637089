import { useMutation } from "@apollo/client";
import { Box, FormLabel, Input, Text, Textarea, Button, useDisclosure, useToast, ButtonProps } from "@chakra-ui/react";
import { FragmentType, gql, useFragment } from "__generated__";
import Drawer from "components/Drawer";
import { useEffect, useState } from "react";
import { getGqlError } from "utils/gpq-helpers";
import { cloneDeep, values } from "lodash";
import numeral from "numeral";
import { SHIPMENT_ENTITY, SHIPMENT_ITEMS_ENTITY } from "./query.graphql";

const defaultInput = {
  description: "",
  quantity: 0,
  unit_price: 0,
};

const createMutation = gql(`
	mutation CreateShipmentItem($input: ShipmentItemInput!) {
		createShipmentItem(data: $input) {
			data {
				id
        ...ShipmentItemFragment
			}
		}
	}
`);

const updateMutation = gql(`
	mutation UpdateShipmentItem($id: ID!, $input: ShipmentItemInput!) {
		updateShipmentItem(id: $id, data: $input) {
			data {
				id
        ...ShipmentItemFragment
			}
		}
	}
`);

interface Props extends ReturnType<typeof useDisclosure> {
  shipmentId: string;
  shipmentItem?: FragmentType<typeof SHIPMENT_ITEMS_ENTITY>;
  onSave?: () => void;
  onClosed?: () => void;
}
export default function ShipmentItemForm({ onSave, onClosed, shipmentId, shipmentItem, ...disclosure }: Props) {
  const { isOpen, onClose } = disclosure;
  const toast = useToast();
  const entity = useFragment(SHIPMENT_ITEMS_ENTITY, shipmentItem);
  const [input, setInput] = useState(defaultInput);

  useEffect(() => {
    if (!entity) return;
    setInput({
      description: entity?.attributes?.description ?? "",
      quantity: entity?.attributes?.quantity ?? 0,
      unit_price: entity?.attributes?.unit_price ?? 0,
    });
  }, [entity]);

  const resetInput = () => setInput(defaultInput);

  const [createShipmentItem, { loading: creating }] = useMutation(createMutation, {
    update(cache, { data }) {
      if (!data?.createShipmentItem?.data) return;
      const frag = cloneDeep(
        cache.readFragment({
          id: `ShipmentEntity:${shipmentId}`,
          fragment: SHIPMENT_ENTITY,
          fragmentName: "ShipmentFragment",
        })
      );

      frag?.attributes?.shipment_items?.data?.push(data?.createShipmentItem?.data);

      cache.writeFragment({
        id: `ShipmentEntity:${shipmentId}`,
        fragment: SHIPMENT_ENTITY,
        fragmentName: "ShipmentFragment",
        data: frag,
      });
      onSave?.();
    },
  });
  const [updateShipmentItem, { loading: updating }] = useMutation(updateMutation);

  const create = async () => {
    try {
      await createShipmentItem({
        variables: {
          input: {
            ...input,
            shipment: shipmentId,
          },
        },
      });
      toast({
        title: "Shipment item added",
        status: "success",
      });
      onClose();
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  const update = async () => {
    if (!entity?.id) return;
    try {
      await updateShipmentItem({
        variables: {
          id: entity?.id,
          input,
        },
      });
      toast({
        title: "Shipment item updated",
        status: "success",
      });
      onClose();
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  const handleSubmit = async () => {
    if (entity) return await update();
    return await create();
  };

  const isValid = values(input).every((value) => !!value);

  return (
    <Drawer
      isOpen={isOpen}
      placement={"right"}
      onClose={onClose}
      size={"md"}
      title="New Shipment Item"
      onCloseComplete={() => {
        resetInput();
        onClosed?.();
      }}
      content={
        <>
          <Box>
            <FormLabel htmlFor="description">Description</FormLabel>
            <Textarea
              id="note"
              placeholder="Item description"
              value={input.description}
              onChange={({ currentTarget: { value } }) => {
                setInput({ ...input, description: value });
              }}
            />
          </Box>

          <Box>
            <FormLabel htmlFor="quantity">Quantity</FormLabel>
            <Input
              id="quantity"
              type="number"
              placeholder="Quanity of the item"
              value={input.quantity || ""}
              onChange={({ currentTarget: { value } }) => {
                setInput({ ...input, quantity: Number(value) });
              }}
            />
          </Box>

          <Box>
            <FormLabel htmlFor="price">Rate</FormLabel>
            <Input
              id="price"
              type="number"
              placeholder="Price of the item"
              value={input.unit_price || ""}
              onChange={({ currentTarget: { value } }) => {
                setInput({ ...input, unit_price: Number(value) });
              }}
            />
          </Box>
          <Text fontSize={"2xl"} textAlign={"right"}>
            Total Payable: {numeral(input.quantity * input.unit_price).format("#0,0.00")}
          </Text>
        </>
      }
      footer={
        <>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button isDisabled={!isValid} isLoading={creating || updating} colorScheme="purple" onClick={handleSubmit}>
            Submit
          </Button>
        </>
      }
    />
  );
}

interface ShipmentFormButtonProps extends ButtonProps, ReturnType<typeof useDisclosure> {}
export function ShipmentFormButton({ onOpen, leftIcon }: ShipmentFormButtonProps) {
  return (
    <Button colorScheme="purple" position={"fixed"} zIndex={99} left={8} leftIcon={leftIcon} onClick={onOpen}>
      New
    </Button>
  );
}
