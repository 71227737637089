import { useState } from "react";
import { InputGroup, Input, InputRightElement, Button, InputProps } from "@chakra-ui/react";

interface Props extends InputProps {}
export default function PasswordInput(props: Props) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup>
      <Input {...props} pr="4.5rem" type={show ? "text" : "password"} />
      <InputRightElement width="4.5rem" mt={1}>
        <Button size="sm" onClick={handleClick}>
          {show ? "Hide" : "Show"}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
