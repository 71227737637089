import { gql } from "__generated__";

const INVOICE_ENTITY = gql(`
	fragment InvoiceFragment on InvoiceEntity {
		id
		attributes {
			owner {
				data {
					attributes {
						firstName,
						lastName
					}
				}
			}
			receiver {
				data {
					attributes {
						firstName
						lastName
					}
				}
			}
			shipment {
				data {
					...ShipmentFragment
					id
					attributes {
						from
						to
					}
				}
			}
			code
			paymentStatus
			paymentGatewayResponse
			createdAt
		}
	}
`);


const SORT = ["createdAt:desc", "updatedAt:desc"];
const QUERY_MANY = gql(`
  query Invoices($filters: InvoiceFiltersInput, $pagination: PaginationArg, $sort: [String]) {
    invoices(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
				id
				...InvoiceFragment
      }
			meta {
				pagination {
					total
					page
					pageSize
					pageCount
				}
			}
    }
  }
`);


const QUERY_ONE = gql(`
  query Invoice($id: ID!) {
    invoice(id: $id) {
      data {
				...InvoiceFragment
				id
				attributes {
					code
				}
      }
    }
  }
`);

const QUERY_BY_CODE = gql(`
  query InvoiceByCode($code: String!) {
    invoiceByCode(code: $code) {
			total
      data {
				id
				code
				createdAt
				paymentStatus
				paymentGatewayResponse
				overcharge
				owner {
					firstName
					lastName
				}
				shipment {
					id
					from
					to
					alien {
						firstName
						lastName
						phone
					}
					receiver {
						firstName
						lastName
						phone
						email
						address
						role {
							name
						}
					}
					shipment_items {
						description
						quantity
						unit_price
					}
				}
      }
    }
  }
`);


export {
	SORT,
	QUERY_MANY,
	QUERY_ONE,
	QUERY_BY_CODE,
	INVOICE_ENTITY
}