import { useQuery } from "@apollo/client";
import { Text, Flex, Divider, Input, Box, useToast } from "@chakra-ui/react";
import Layout from "components/Layout";
import { endOfMonth, format, parse, startOfMonth } from "date-fns";
import { ChangeEvent, useEffect, useState } from "react";
import usePagination from "utils/hooks/usePagination";
import { SORT, QUERY_MANY } from "components/invoice/query.graphql";
import { orderBy, uniqBy } from "lodash";
import InfiniteList from "components/InfiniteList";
import InvoiceRow from "components/invoice/InvoiceRow";
import numeral from "numeral";
import useInvoiceMutations from "utils/hooks/useInvoiceMutations";
import { getGqlError } from "utils/gpq-helpers";
import { SearchInput } from "components/SearchInput";
import useDebouncedState from "utils/hooks/useDebouncedState";
import { InvoiceFiltersInput } from "__generated__/graphql";

export default function Shipments() {
  const { pagination, loadMore, reset: resetPagination } = usePagination({ page: 1, pageSize: 10 });
  const [filters, setFilters] = useState<InvoiceFiltersInput>({
    createdAt: {
      gte: startOfMonth(new Date()),
      lte: new Date(),
    },
  });
  const [search, setSearch, searchValue] = useDebouncedState<string>(undefined, 1000);
  const { data, loading: queryLoading } = useQuery(QUERY_MANY, {
    variables: { pagination, filters, sort: SORT },
  });
  const [list, setList] = useState(data?.invoices?.data ?? []);
  const {
    delete: { deleteInvoice, deleting },
  } = useInvoiceMutations({
    pagination,
    filters,
    sort: SORT,
  });
  const toast = useToast();

  useEffect(() => {
    if (data?.invoices?.data) {
      const newData = data?.invoices?.data;
      setList((current) => orderBy(uniqBy([...current, ...newData], "id"), (v) => numeral(v.id).value(), "desc"));
    }
  }, [data]);

  useEffect(() => {
    if (search !== undefined) {
      resetPagination();
      setList([]);
      setFilters((f) => ({
        ...f,
        code: { containsi: search },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onChangeMonth = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (!value) return;
    resetPagination();
    setList([]);
    setFilters({
      createdAt: {
        gte: startOfMonth(parse(value, "yyyy-MM", new Date())),
        lte: endOfMonth(parse(value, "yyyy-MM", new Date())),
      },
      ...(search !== undefined ? { code: { containsi: search } } : {}),
    });
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteInvoice({
        variables: { id },
      });
      setList((current) => current.filter((v) => v.id !== id));
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  return (
    <Layout
      headerBottom={
        <>
          <Divider my={4} colorScheme={"purple"} />
          <Flex width={"100%"} justifyContent={"space-between"} alignItems={"center"} mt={4}>
            <Text fontSize={"3xl"}>Invoices</Text>
            <Input
              type="month"
              maxW="200px"
              onChange={onChangeMonth}
              value={format(filters?.createdAt?.gte, "yyyy-MM")}
              max={format(new Date(), "yyyy-MM")}
            />
          </Flex>
        </>
      }
    >
      <Box w={{ base: "100%", lg: "800px" }}>
        <InfiniteList
          headerComponent={
            <SearchInput
              size={"lg"}
              placeholder="Search by location, recevier etc..."
              value={searchValue}
              onChange={(e) => setSearch(e.target.value)}
              onClear={() => setSearch("")}
            />
          }
          list={list}
          threshhold={2}
          isLoading={queryLoading}
          renderItem={(item) => <InvoiceRow invoice={item} deleting={deleting} onDelete={handleDelete} />}
          onReachEnd={() => loadMore(data?.invoices?.meta.pagination.pageCount ?? 0)}
          emptyComponent={<Text fontSize={"4xl"}>No Invoice found</Text>}
        />
      </Box>
    </Layout>
  );
}
