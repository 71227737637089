import { useQuery } from "@apollo/client";
import { gql, FragmentType, useFragment } from "__generated__";
import { InputMaybe, UsersPermissionsUserFiltersInput } from "__generated__/graphql";

const USER_FRAGMENT = gql(`
	fragment UserFragment on UsersPermissionsUserEntity {
		id
		attributes {
			username
			email
			blocked
			firstName
			lastName
			alias
			createdAt
			updatedAt
			role {
				data {
					attributes {
						name
					}
				}
			}
		}
	}			
`);

export type USERS_TYPE = FragmentType<typeof USER_FRAGMENT>;

const query = gql(`
		query Users($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg) {
			usersPermissionsUsers(filters: $filters, pagination: $pagination) {
				data {
					...UserFragment
				}
			}
		}
	`);

interface Props {
	filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
}
export default function useUsers({ filters }: Props) {
	const { data, loading } = useQuery(query, {
		variables: {
			filters,
			pagination: {
				page: 1,
				pageSize: 10,
			}
		},
	});
	const result = data?.usersPermissionsUsers?.data;
	const users = useFragment(USER_FRAGMENT, result);

	return { data: users ?? [], loading };
}
