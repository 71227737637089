import { Flex, HStack, useColorModeValue } from "@chakra-ui/react";
import { ColorModeSwitcher } from "components/ColorModeSwitcher";
import { Link } from "react-router-dom";
import Logo from "./Logo";

interface Props {
  bottom?: React.ReactNode;
}
export default function Header({ bottom }: Props) {
  const bg = useColorModeValue("white", "gray.900");

  return (
    <Flex position={"sticky"} zIndex={999} top={0} direction={"column"} px={4} py={2} mb={8} bg={bg} shadow="md">
      <HStack w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Link to={"/"}>
          <Logo />
        </Link>
        <ColorModeSwitcher />
      </HStack>
      {bottom}
    </Flex>
  );
}
