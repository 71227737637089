import { Text, View, StyleSheet } from "@react-pdf/renderer";
import numeral from "numeral";
import { CustomShipment, Maybe, ShipmentItem } from "__generated__/graphql";

interface Props {
  shipment: Maybe<CustomShipment>;
  overcharge?: number;
  total: number;
}

const columns = ["#", "Description", "Qty", "Rate", "Total"];
const columnWidths = ["10%", "45%", "10%", "15%", "20%"];
const columnAlignments: Array<"left" | "right"> = ["left", "left", "right", "right", "right"];

export default function InvoicePdfItems({ shipment, overcharge, total }: Props) {
  const shipmentItems = shipment?.shipment_items;
  if (!shipmentItems) return null;

  return (
    <View style={styles.container}>
      <Header />
      {shipmentItems.map((shipmentItem, index) => (
        <Row key={`shipment-item-${index}`} shipmentItem={shipmentItem} index={index} />
      ))}
      {overcharge && (
        <Row
          key="overcharge"
          shipmentItem={{ description: "DEMURRAGE", quantity: 1, unit_price: overcharge }}
          index={shipmentItems.length}
        />
      )}
      <Footer total={total} />
    </View>
  );
}

function Header() {
  return (
    <View style={[styles.tableHeader, styles.bold]}>
      {columns.map((column, index) => (
        <Text
          key={index}
          style={[styles.columnHead, { width: columnWidths[index], textAlign: columnAlignments[index] }]}
        >
          {column}
        </Text>
      ))}
    </View>
  );
}

interface RowProps {
  shipmentItem?: Maybe<ShipmentItem>;
  index: number;
}
function Row({ shipmentItem, index }: RowProps) {
  const { quantity, unit_price, description } = shipmentItem ?? {};
  const total = (quantity ?? 0) * (unit_price ?? 0);

  return (
    <View style={[styles.row, { fontSize: 14 }]}>
      <Text style={[styles.rowText, { width: columnWidths[0], textAlign: columnAlignments[0] }]}>{index + 1}</Text>
      <Text style={[styles.rowText, { width: columnWidths[1], textAlign: columnAlignments[1] }]}>{description}</Text>
      <Text style={[styles.rowText, { width: columnWidths[2], textAlign: columnAlignments[2] }]}>{quantity}</Text>
      <Text style={[styles.rowText, { width: columnWidths[3], textAlign: columnAlignments[3] }]}>
        {numeral(unit_price).format("0,00.00")}
      </Text>
      <Text style={[styles.rowText, { width: columnWidths[4], textAlign: columnAlignments[4] }]}>
        {numeral(total).format("0,0.00")}
      </Text>
    </View>
  );
}

interface FooterProps {
  total: number;
}
function Footer({ total }: FooterProps) {
  return (
    <View style={styles.tableFooter}>
      <Text style={[styles.rowText, styles.tableFooterLeft]}>Grand Total</Text>
      <Text style={[styles.columnHead, styles.tableFooterRight, styles.bold]}>N{numeral(total).format("0,0.00")}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  tableHeader: {
    flexDirection: "row",
    paddingVertical: 5,
    backgroundColor: "#c5c5c5",
  },
  columnHead: {
    fontSize: 16,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c5c5c5",
    borderBottomStyle: "solid",
    paddingVertical: 5,
  },
  rowText: {
    fontSize: 14,
  },
  tableFooter: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  tableFooterLeft: {
    width: "80%",
    textAlign: "right",
  },
  tableFooterRight: {
    width: "20%",
    textAlign: "right",
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Courier-Bold",
  },
});
