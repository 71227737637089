import { useState } from "react";
import { useDebounce } from "usehooks-ts";

type ReturnType<T> = [T | undefined, (v: T | undefined) => void, T | undefined];

export default function useDebouncedState<T>(v?: T | (() => T), debounce = 500): ReturnType<T> {
  const [state, setState] = useState(v);
  const debouncedState = useDebounce(state, debounce);
  return [debouncedState, setState, state];
}
