import { Text, Box, Input, Button, VStack, useToast } from "@chakra-ui/react";
import Layout from "components/Layout";
import { gql } from "__generated__/gql";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import Logo from "components/Logo";
import { ColorModeSwitcher } from "components/ColorModeSwitcher";
import Link from "components/Link";

const forgotPassword = gql(`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`);
export default function ForgotPassword() {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [mutation, { data, loading, error }] = useMutation(forgotPassword);

  // valid email memo
  const validEmail = useMemo(() => {
    return email && email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
  }, [email]);

  const handleSubmit = async () => {
    void mutation({ variables: { email } });
  };

  useEffect(() => {
    if (error) toast({ title: error.message, status: "error" });
    if (data?.forgotPassword?.ok) {
      toast({
        title: "You will receive an email with a link to reset your password.",
        status: "success",
      });
      setEmail("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, toast]);

  return (
    <Layout hideHeader>
      <VStack spacing={8} my={"auto"}>
        <ColorModeSwitcher position={"fixed"} top={4} right={4} zIndex={999} />
        <Logo width={240} />
        <Box w={{ base: "320px", md: "400px", lg: "480px" }} shadow={"2xl"} px={4} py={16} rounded={8}>
          <VStack spacing={4}>
            <Text fontSize={"3xl"}>Forgot password</Text>
            <Input
              placeholder="Enter email"
              size={"lg"}
              value={email}
              onChange={({ currentTarget: { value } }) => setEmail(value)}
            />
            <Button
              w="100%"
              colorScheme="purple"
              size={"lg"}
              onClick={handleSubmit}
              isDisabled={!validEmail}
              isLoading={loading}
            >
              Continue
            </Button>
          </VStack>
        </Box>
        <Link to={"/"}>Back to login</Link>
      </VStack>
    </Layout>
  );
}
