import { useMutation } from "@apollo/client";
import { Box, FormLabel, Textarea, Button, useDisclosure, useToast } from "@chakra-ui/react";
import { FragmentType, gql, useFragment } from "__generated__";
import { Select, CreatableSelect } from "chakra-react-select";
import Drawer from "components/Drawer";
import { useEffect, useState } from "react";
import { getGqlError } from "utils/gpq-helpers";
import useCurrentUser from "utils/hooks/useCurrentUser";
import useUsers from "utils/hooks/useUsers";
import { SHIPMENT_ENTITY, SORT, QUERY_MANY as query } from "./query.graphql";
import { Alien, Pagination } from "types";
import { InputMaybe, ShipmentFiltersInput, ShipmentsQuery } from "__generated__/graphql";
import AlienFields from "components/AlienFields";
import { omit } from "lodash";
import useDebouncedState from "utils/hooks/useDebouncedState";
import useShipmentLocations from "utils/hooks/useShipmentLocations";

// TODO - fix edit

const defaultShipmentInput = {
  from: "",
  to: "",
  note: "",
  receiver: "",
};

const createMutation = gql(`
	mutation CreateShipment($input: ShipmentInput!) {
		createShipment(data: $input) {
			data {
				id
        ...ShipmentFragment
			}
		}
	}
`);

const updateMutation = gql(`
	mutation UpdateShipment($id: ID!, $input: ShipmentInput!) {
		updateShipment(id: $id, data: $input) {
			data {
				id
        ...ShipmentFragment
			}
		}
	}
`);

const createAlienMutation = gql(`
	mutation CreateAlien($input: AlienInput!) {
		createAlien(data: $input) {
			data {
				id
        ...AlienFragment
			}
		}
	}
`);

interface Props {
  open?: boolean;
  pagination: Pagination;
  filters: InputMaybe<ShipmentFiltersInput>;
  edit?: FragmentType<typeof SHIPMENT_ENTITY>;
  onSave?: (arg: NonNullable<ShipmentsQuery["shipments"]>["data"]) => void;
  onClosed?: () => void;
}
export default function ShipmentForm({ open, pagination, filters, edit, onSave, onClosed }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const [shipmentInput, setShipmentInput] = useState(defaultShipmentInput);
  const [customerFilter, setCustomerFilter] = useDebouncedState("", 1000);
  const { data: customers, loading: isUsersLoading } = useUsers({
    filters: {
      role: {
        name: { in: ["Customer", "Alien"] },
      },
      or: [
        { firstName: { containsi: customerFilter } },
        { lastName: { containsi: customerFilter } },
        { phone: { containsi: customerFilter } },
        { alias: { containsi: customerFilter } },
      ],
    },
  });
  const [isAlien, setIsAlien] = useState(false);
  const [alienInput, setAlienInput] = useState<Partial<Alien>>({});
  const entity = useFragment(SHIPMENT_ENTITY, edit);

  useEffect(() => {
    if (edit || open) onOpen();
    if (!entity) return;
    const { attributes } = entity;
    setCustomerFilter(attributes?.receiver?.data?.attributes?.phone ?? "");
    setShipmentInput({
      from: attributes?.from ?? "",
      to: attributes?.to ?? "",
      note: attributes?.note ?? "",
      receiver: attributes?.receiver?.data?.id ?? "",
    });
    if (attributes?.alien?.data) {
      setIsAlien(true);
      setAlienInput({
        id: attributes?.alien?.data?.id ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, entity, open]);

  const resetShipmentInput = () => {
    setShipmentInput(defaultShipmentInput);
    setIsAlien(false);
  };
  const customerOptions = customers.map(({ id, attributes }) => ({
    value: id ?? "",
    label: `${attributes?.firstName} ${attributes?.lastName} ${attributes?.alias ? `(${attributes?.alias})` : ""}`,
  }));

  const [createShipment, { loading: mutationLoading }] = useMutation(createMutation, {
    update(cache, { data }) {
      if (!data?.createShipment) return;
      const newShipment = data?.createShipment;
      const shipmentList = cache.readQuery({
        query,
        variables: { pagination, filters, sort: SORT },
      })?.shipments;
      if (!shipmentList) return;
      const newList = [newShipment.data ?? {}, ...shipmentList.data];
      cache.writeQuery({
        query,
        variables: { pagination, filters, sort: SORT },
        data: {
          shipments: {
            data: newList,
            meta: shipmentList.meta,
          },
        },
      });
      onSave?.(newList);
    },
  });
  const [updateShipment] = useMutation(updateMutation);
  const [createAlien] = useMutation(createAlienMutation);

  const handleCreate = async (alienId?: string | null) => {
    await createShipment({
      variables: {
        input: {
          ...shipmentInput,
          alien: alienId,
          owner: currentUser?.id ?? "",
        },
      },
    });
    return "Shipments created";
  };

  const handleUpdate = async (alienId?: string | null) => {
    await updateShipment({
      variables: {
        id: entity?.id ?? "",
        input: {
          ...shipmentInput,
          alien: alienId,
        },
      },
    });
    onSave?.([]);
    return "Shipments updated";
  };

  const handleSubmit = async () => {
    let alienId = isAlien ? alienInput.id : null;
    try {
      if (isAlien && !alienInput.id) {
        const { data } = await createAlien({
          variables: {
            input: {
              ...omit(alienInput, ["id"]),
            },
          },
        });
        alienId = data?.createAlien?.data?.id ?? "";
      }
      toast({
        title: entity ? await handleUpdate(alienId) : await handleCreate(alienId),
        status: "success",
      });
      onClose();
    } catch (error: any) {
      toast({
        title: getGqlError(error),
        status: "error",
      });
    }
  };

  const validAlien = (!!alienInput.firstName && !!alienInput.lastName && !!alienInput.phone) || !!alienInput.id;
  const isValid =
    !!shipmentInput.from && !!shipmentInput.to && !!shipmentInput.receiver && (isAlien ? validAlien : true);
  const initialAlienValue = entity?.attributes?.alien?.data
    ? {
        firstName: entity?.attributes?.alien?.data?.attributes?.firstName ?? "",
        lastName: entity?.attributes?.alien?.data?.attributes?.lastName ?? "",
        phone: entity?.attributes?.alien?.data?.attributes?.phone ?? "",
      }
    : undefined;

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement={"right"}
        onClose={onClose}
        size={"md"}
        title="New Shipment"
        onCloseComplete={() => {
          resetShipmentInput();
          onClosed?.();
        }}
        content={
          <>
            <Box>
              <FormLabel htmlFor="receiver">Receiver</FormLabel>
              <Select
                inputId="receiver"
                options={customerOptions}
                value={customerOptions.find((v) => v.value === shipmentInput.receiver)}
                onChange={(value) => {
                  setShipmentInput({
                    ...shipmentInput,
                    receiver: value?.value ?? "",
                  });
                  if (value?.label.toLowerCase().includes("alien")) setIsAlien(true);
                  else setIsAlien(false);
                }}
                onInputChange={setCustomerFilter}
                isLoading={isUsersLoading}
                isClearable={!!shipmentInput.receiver}
              />
            </Box>
            {isAlien && (
              <AlienFields
                initalValue={initialAlienValue}
                onChange={(v) => setAlienInput({ ...v, id: "" })}
                onSelect={(v) => setAlienInput({ ...alienInput, id: v })}
              />
            )}
            <Box>
              <FormLabel htmlFor="from">From</FormLabel>
              <LocationInput
                id="from"
                value={shipmentInput.from}
                onChange={(v) => setShipmentInput({ ...shipmentInput, from: v })}
                locationType="from"
              />
            </Box>
            <Box>
              <FormLabel htmlFor="to">To</FormLabel>
              <LocationInput
                id="to"
                value={shipmentInput.to}
                onChange={(v) => setShipmentInput({ ...shipmentInput, to: v })}
                locationType="to"
              />
            </Box>
            <Box>
              <FormLabel htmlFor="note">Note</FormLabel>
              <Textarea
                id="note"
                placeholder="Optional"
                value={shipmentInput.note}
                onChange={({ currentTarget: { value } }) => {
                  setShipmentInput({ ...shipmentInput, note: value });
                }}
                rows={8}
              />
            </Box>
          </>
        }
        footer={
          <>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button isDisabled={!isValid} isLoading={mutationLoading} colorScheme="purple" onClick={handleSubmit}>
              Submit
            </Button>
          </>
        }
      />
    </>
  );
}

interface LocationInputProps {
  id?: string;
  value: string;
  onChange: (value: string) => void;
  locationType: "from" | "to";
}

export function LocationInput({ id, value, onChange, locationType }: LocationInputProps) {
  const inputValue = value;
  const [locationFilter, setLocationFilter] = useState("");
  const { data, loading } = useShipmentLocations({
    filters: {
      ...(locationType === "from" && { from: { containsi: locationFilter } }),
      ...(locationType === "to" && { to: { containsi: locationFilter } }),
    },
  });

  const options = data[locationType].map((o) => ({ value: o, label: o }));

  return (
    <CreatableSelect
      inputId={id}
      options={options}
      value={options.find((v) => v.value === inputValue)}
      defaultValue={inputValue ? { value: inputValue, label: inputValue } : undefined}
      onChange={(value) => {
        onChange(value?.value ?? "");
      }}
      onInputChange={setLocationFilter}
      isLoading={loading}
      placeholder={`Location of ${locationType === "from" ? "origin" : "delivery"}`}
      isClearable={!!inputValue}
    />
  );
}
