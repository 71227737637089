import { FragmentType, useFragment } from "__generated__";
import { SHIPMENT_ENTITY, SHIPMENT_ITEMS_ENTITY } from "./query.graphql";
import { sum, values } from "lodash";
import { useEffect, useMemo, useState } from "react";

interface Props {
  shipment: FragmentType<typeof SHIPMENT_ENTITY>;
}

export default function ShipmentItemsCount({ shipment }: Props) {
  const { attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const [totals, setTotals] = useState<Record<string, number>>({});
  const items = attributes?.shipment_items?.data ?? [];

  const total = useMemo(() => sum(values(totals)), [totals]);

  return (
    <>
      {items.map((item, index) => (
        <Quantity
          key={index}
          shipmentItem={item}
          onQuantityChange={(quantity) => {
            setTotals((prev) => ({ ...prev, [index]: quantity }));
          }}
        />
      ))}
      {total}
    </>
  );
}

interface QuantityProps {
  shipmentItem: FragmentType<typeof SHIPMENT_ITEMS_ENTITY>;
  onQuantityChange?: (quantity: number) => void;
}
function Quantity({ shipmentItem, onQuantityChange }: QuantityProps) {
  const { attributes } = useFragment(SHIPMENT_ITEMS_ENTITY, shipmentItem);
  const quantity = attributes?.quantity ?? 0;
  useEffect(() => {
    onQuantityChange?.(quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  return <></>;
}
