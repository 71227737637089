import { LoaderFunctionArgs, redirect } from "react-router-dom";
import client from "./apollo-client";
import { gql } from "@apollo/client";
import { Role } from "types";
import { isEmpty } from "lodash";

interface AuthProp {
	signedInRedirect?: string;
	signedOutRedirect?: string;
	roles?: Role[];
}
const auth =
	({ signedInRedirect, signedOutRedirect, roles }: AuthProp) =>
		async ({ request }: LoaderFunctionArgs) => {
			try {
				const {
					data: { loggedInUser },
				} = await client.query({
					query: gql`
						query RootQuery {
							loggedInUser: me {
								__typename
								id
								email
								role {
									name
								}
							}
						}
					`
				});
				if (signedInRedirect) return redirect(signedInRedirect);
				if (roles && !isEmpty(loggedInUser)) {
					const userRole = loggedInUser.role.name;
					if (!roles.includes(userRole)) {
						return redirect(signedOutRedirect || "/");
					}
				}
				return loggedInUser;
			} catch (error) {
				// delete the token
				localStorage.removeItem("token");
				if (signedOutRedirect) return redirect(signedOutRedirect);
				return null;
			}
		};

export default auth;