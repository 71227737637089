import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FragmentType, useFragment } from "__generated__";
import { format } from "date-fns";
import { MouseEvent } from "react";
import { MdSend, MdInventory, MdEdit, MdDelete } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useCopyToClipboard } from "usehooks-ts";
import { SHIPMENT_ENTITY } from "./query.graphql";
import useConfiguration from "utils/hooks/useConfiguration";
import ShipmentItemsCount from "./ShipmentItemsCount";
import useOmic from "utils/hooks/useOmic";

interface Props {
  shipment: FragmentType<typeof SHIPMENT_ENTITY>;
  editable?: boolean;
  onEdit?: (arg: FragmentType<typeof SHIPMENT_ENTITY>) => void;
  onDelete?: (arg: string) => void;
  deleting?: boolean;
}
export default function ShipmentRow({ shipment, editable, deleting, onEdit, onDelete }: Props) {
  const { id, attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const [, copy] = useCopyToClipboard();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { config } = useConfiguration();

  const invoiceLink = `${config?.FRONTEND_URL}/invoices/${attributes?.invoice?.data?.attributes?.code}`;
  const hasPaid = !!attributes?.invoice?.data?.attributes?.paymentStatus;
  const hasInvoice = !!attributes?.invoice?.data;
  const isFulfilled = !!attributes?.fulfilment?.data;
  const hasShipmentItems = !!attributes?.shipment_items?.data?.length;

  const handleCopy = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!attributes?.invoice?.data?.attributes?.code) return;
    copy(invoiceLink);
    toast({
      title: "Invoice link copied to clipboard",
      status: "success",
      duration: 15000,
    });
  };

  const handleOnClick = (id: string) => {
    const url = `/shipments/${id}`;
    if (location.pathname !== url) navigate(`/shipments/${id}`);
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (toast.isActive(`delete-${id}`)) return;
    toast({
      id: `delete-${id}`,
      render: ({ onClose }) => (
        <Box color="white" p={3} bg="red.500" rounded={4}>
          <Text>Are you sure you want to delete this shipment?</Text>
          <ButtonGroup w={"100%"} justifyContent={"end"} mt={3} spacing={3}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme="red"
              isLoading={deleting}
              isDisabled={deleting}
              onClick={() => {
                onDelete?.(id ?? "");
                onClose();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </Box>
      ),
    });
  };

  const receiverName = !!attributes?.alien?.data
    ? `${attributes?.alien?.data?.attributes?.firstName} ${attributes?.alien?.data?.attributes?.lastName} (ALIEN)`
    : `${attributes?.receiver?.data?.attributes?.firstName} ${attributes?.receiver?.data?.attributes?.lastName}`;

  return (
    <Card
      key={`shipment-${id}`}
      width={{ lg: "800px" }}
      bg={"gray.700"}
      opacity={isFulfilled ? 0.3 : 1}
      textColor={"white"}
      cursor={"pointer"}
      onClick={() => handleOnClick(id ?? "")}
    >
      <CardBody>
        <Flex alignItems={"center"} flexWrap={"wrap"}>
          <HStack w={[300]} textAlign={"start"} alignItems={"flex-start"} my={2}>
            <Icon as={MdSend} my={2} fontSize={36} />
            <Box>
              <Text>From: {attributes?.from}</Text>
              <Text>
                {attributes?.owner?.data?.attributes?.firstName} {attributes?.owner?.data?.attributes?.lastName}
              </Text>
            </Box>
          </HStack>
          <HStack w={[300]} textAlign={"start"} alignItems={"flex-start"} my={2}>
            <Icon as={MdInventory} my={2} fontSize={36} />
            <Box>
              <Text>To: {attributes?.to}</Text>
              <Text>{receiverName}</Text>
            </Box>
          </HStack>
          <HStack w={100} justifyContent={"flex-end"} position={"absolute"} top={4} right={8}>
            <Text fontSize={"5xl"} margin={0} lineHeight={1}>
              <ShipmentItemsCount shipment={shipment} />
              <sub style={{ fontSize: 12 }}>Items</sub>
            </Text>
          </HStack>
        </Flex>
        <Divider my={2} />
        <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
          <HStack w={[350]} my={1}>
            <Text _hover={{ opacity: 0.8 }} cursor="pointer" onClick={(e) => handleCopy(e)}>
              #{attributes?.invoice?.data?.attributes?.code ?? "NO INVOICE"}
            </Text>
            <Text>|</Text>
            <Badge fontSize={"xl"} px={3} colorScheme={hasPaid ? "green" : "red"} rounded={4} fontWeight={"bold"}>
              {hasPaid ? "PAID" : "UNPAID"}
            </Badge>
          </HStack>
          <Text fontSize={"small"} my={1}>
            Created:
            {format(new Date(attributes?.createdAt), "dd/MM/yyyy hh:mm a")}
          </Text>
        </Flex>
        {!hasInvoice && editable && (
          <HStack my={2} position={"absolute"} top={-6} right={4}>
            <IconButton
              icon={<MdEdit />}
              aria-label="Edit Shipment"
              colorScheme="blue"
              size={"sm"}
              onClick={(e) => {
                e.stopPropagation();
                onEdit?.(shipment);
              }}
            />
            {!hasShipmentItems && (
              <IconButton
                icon={<MdDelete />}
                aria-label="Delete Shipment"
                colorScheme="red"
                size={"sm"}
                onClick={handleDelete}
              />
            )}
          </HStack>
        )}
      </CardBody>
    </Card>
  );
}

export function ShipmentRowCheckbox({ shipment }: { shipment: FragmentType<typeof SHIPMENT_ENTITY> }) {
  const { attributes } = useFragment(SHIPMENT_ENTITY, shipment);
  const { batchPay, invoices, addBatchPayInvoice, removeBatchPayInvoce } = useOmic();
  const checkboxBg = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const checkboxBorder = useColorModeValue("gray.50", "gray.800");

  const paid = !!attributes?.invoice?.data?.attributes?.paymentStatus;
  const code = attributes?.invoice?.data?.attributes?.code;
  const hasInvoice = !!attributes?.invoice?.data;

  if (paid || !batchPay || !hasInvoice) return null;

  return (
    <Box position={"absolute"} bg={checkboxBg} rounded={"2xl"} zIndex={1} top={-4} left={4}>
      <Checkbox
        isChecked={invoices.includes(code ?? "")}
        onChange={(e) => {
          if (e.target.checked) {
            addBatchPayInvoice(code ?? "");
          } else {
            removeBatchPayInvoce(code ?? "");
          }
        }}
        size={"lg"}
        zIndex={2}
        borderColor={checkboxBorder}
        colorScheme="green"
        p={2}
      />
    </Box>
  );
}
