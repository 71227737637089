import { ReactNode } from "react";
import { Link as CLink } from "@chakra-ui/react";
import { Link as RLink } from "react-router-dom";

interface Props {
  to: string;
  children: ReactNode;
}
export default function Link({ to, children }: Props) {
  return (
    <CLink as={RLink} to={to}>
      {children}
    </CLink>
  );
}
