import { useMutation } from "@apollo/client";
import { gql } from "__generated__";
import { InputMaybe, PaginationArg, InvoiceFiltersInput } from "__generated__/graphql";
import { QUERY_MANY } from "components/invoice/query.graphql";

const deleteMutation = gql(`
  mutation DeleteInvoice($id: ID!) {
    deleteInvoice(id: $id) {
      data {
        id
      }
    }
  }
`);

interface Options {
	pagination: InputMaybe<PaginationArg> | undefined;
	filters: InputMaybe<InvoiceFiltersInput> | undefined;
	sort: InputMaybe<string[]> | undefined;
}

export default function useInvoiceMutations({ pagination, filters, sort }: Partial<Options>) {
	const [deleteInvoice, { loading: deleting }] = useMutation(deleteMutation, {
		update(cache, { data }) {
			if (!data?.deleteInvoice) return;
			const invoices = cache.readQuery({
				query: QUERY_MANY,
				variables: { pagination, filters, sort },
			})?.invoices;
			if (!invoices) return;
			cache.writeQuery({
				query: QUERY_MANY,
				variables: { pagination, filters, sort },
				data: {
					invoices: {
						...invoices,
						data: invoices.data.filter((invoice) => invoice.id !== data.deleteInvoice?.data?.id),
					}
				}
			});
		},
	});

	return {
		delete: { deleteInvoice, deleting },
	}
}