import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

const uri = process.env.REACT_APP_GQL ?? "";

const authLink = setContext((_, { headers, ...rest }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      ...(token && { authorization: `Bearer ${token}` }),
    }
  }
});

const link = createHttpLink({
  uri
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link)
});

export default client;
